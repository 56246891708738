import React from 'react';
import styled from 'styled-components';
import imagen1 from './1.png';
import imagen2 from './2.png';
import imagen3 from './3.png';
import imagen4 from './4.png';

// Estilos para el contenedor principal con grid
const GridContainer = styled.div`
padding: 9px;
  margin-top: 100px;
  font-family: 'Kanit-ExtraLight', sans-serif;
  display: grid;
  grid-template-columns: repeat(2, 1fr); /* 2 columnas en móvil */


  @media (min-width: 768px) {
   display: none !important;;
  }
`;

// Estilos para cada uno de los sub-contenedores
const SubContainer = styled.div`
  color: #f0f0f0;
  padding: 9px;
  margin-top: 10px;
  z-index: 4;
  font-size: 3.6vw;
  line-height: 1.2;
  text-align: center;
  display: flex;
  flex-direction: column; /* Permite alinear la imagen y el título en columna */
  justify-content: center; /* Centrar horizontalmente */
  align-items: center; /* Centrar verticalmente */
 
  


  .image {
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    min-width: 50%;
    
    height: 100%; /* Alto completo */
    display: flex;
    justify-content: center; /* Centra horizontalmente */
    align-items: center; /* Centra verticalmente */
  }

  .title {

    font-size: 1em; /* Ajusta el tamaño del título */
    font-family: 'Kanit-SemiBolt', sans-serif;
    margin-top:0px;
   
  }
`;

const Contenedor8 = () => {
  return (
    <GridContainer>
      <SubContainer style={{ gridArea: '1 / 1 / span 1 / span 1' }}>
        <div className="image" style={{ backgroundImage: `url(${imagen1})` }}></div>
        <div className="title">RESEARCH</div>
      </SubContainer>
      <SubContainer style={{ gridArea: '2 / 1 / span 1 / span 1' }}>
        La base de buenos resultados se centra en el análisis exhaustivo, identificar oportunidades y conocer a los usuarios que conectan directamente con la marca.
      </SubContainer>

      <SubContainer style={{ gridArea: '1 / 2 / span 1 / span 1' }}>
        Trabajamos a conciencia desde el principio, pero con una mentalidad de negocio, tratamos de lanzar lo antes posible para poder iterar y crecer el producto hasta su versión más elevada.
      </SubContainer>
      <SubContainer style={{ gridArea: '2 / 2 / span 1 / span 1' }}>
        <div className="image" style={{ backgroundImage: `url(${imagen2})` }}></div>
        <div className="title">PRODUCTO MÍNIMO
        VIABLE</div>
      </SubContainer>

      <SubContainer style={{ gridArea: '3 / 1 / span 1 / span 1' }}>
        <div className="image" style={{ backgroundImage: `url(${imagen3})` }}></div>
        <div className="title">TESTING</div>
      </SubContainer>
      <SubContainer style={{ gridArea: '4 / 1 / span 1 / span 1' }}>
        Aplicamos métodos científicos para validar y evolucionar tu producto mientras se mejora la experiencia.
      </SubContainer>

      <SubContainer style={{ gridArea: '3 / 2 / span 1 / span 1' }}>
        Nos apalancamos en estrategias heredadas de hipótesis, experimentación, iteración y testing, para conseguir un crecimiento sostenible.
      </SubContainer>
      <SubContainer style={{ gridArea: '4 / 2 / span 1 / span 1' }}>
        <div className="image" style={{ backgroundImage: `url(${imagen4})` }}></div>
        <div className="title">GROWTH</div>
      </SubContainer>
    </GridContainer>
  );
};

export default Contenedor8;
