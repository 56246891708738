import React, { useRef, useEffect, useState } from 'react';
import styled, { keyframes, css } from 'styled-components';
import BRANDING from './BRANDING.png'; // Asegúrate de tener la ruta correcta de la imagen

// Definimos una animación con keyframes
const slideIn = keyframes`
  from {
    transform: translateX(-100%);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
`;

const ImageWrapper = styled.div.withConfig({
  shouldForwardProp: (prop) => prop !== 'isVisible'
})`
margin-top: 0px;
  z-index: 4;
  width: 45%; 
  opacity: ${props => (props.isVisible ? 1 : 0)};
  animation: ${props =>
    props.isVisible &&
    css`
      ${slideIn} 1.5s ease-out
    `};
  transition: opacity 0.5s ease-out;

  margin-left: 100px; // Ajustar según sea necesario
  text-align: left; // Alinear el contenido a la izquierda


  @media (max-width: 768px) {
    margin-left: 20px; 
    margin-top: 70px;
    width: 240%; 

  }
`;



const Img = styled.img`
  z-index: 4;
  position: relative;
  width: 100%;  
  opacity: 1; 
  
  @media (max-width: 768px) {

    margin: 0 auto; /* Centra horizontalmente */
    width: 36%;
  }
`;
const LetraWorks = () => {
  const [isVisible, setIsVisible] = useState(false);
  const imgRef = useRef();

  useEffect(() => {
    const currentImgRef = imgRef.current;

    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setIsVisible(true);
          if (currentImgRef) {
            observer.unobserve(currentImgRef); // Dejar de observar una vez que la imagen es visible
          }
        }
      },
      { threshold: 0.1 } 
    );

    if (currentImgRef) {
      observer.observe(currentImgRef);
    }

    return () => {
      if (currentImgRef) {
        observer.unobserve(currentImgRef);
      }
    };
  }, []);

  return (
    <ImageWrapper ref={imgRef} isVisible={isVisible}>
      <Img src={BRANDING} alt="BRANDING" />
    </ImageWrapper>
  );
};

export default LetraWorks;
