import React, { useRef, useEffect, useState } from 'react';
import styled from 'styled-components';
import StarImage from './Star.png'; // Ajusta la ruta de la imagen según la ubicación real

const CardContainer = styled.div.withConfig({
  shouldForwardProp: (prop) => prop !== 'isScrollable'
})`
  margin-top: 90px;
  display: flex;
  justify-content: center;
  padding: 1vw 9vw;
  overflow-x: ${({ isScrollable }) => (isScrollable ? 'scroll' : 'hidden')}; /* Controla el desplazamiento horizontal */
  -webkit-overflow-scrolling: touch; /* Desplazamiento suave en dispositivos táctiles */
  scrollbar-width: none; /* Oculta la barra de desplazamiento en Firefox */
  cursor: ${({ isScrollable }) => (isScrollable ? 'grab' : 'default')}; /* Cambia el cursor si es desplazable */
  user-select: none; /* Desactiva la selección de texto */

  &::-webkit-scrollbar {
    display: none; /* Oculta la barra de desplazamiento en Chrome, Safari y Opera */
  }

  &:active {
    cursor: ${({ isScrollable }) => (isScrollable ? 'grabbing' : 'default')}; /* Cambia el cursor a una mano cerrada mientras se arrastra */
  }

  @media (max-width: 1620px) {
    top: 0px;
    justify-content: start;
    padding: 2vw 5vw; /* Ajuste en el padding */
  }
`;

const CardWrapper = styled.div`
  display: flex;
  flex-wrap: nowrap; /* Para evitar el salto de línea */
  width: max-content; /* Asegura que el contenedor se ajuste al contenido */
`;

const Card = styled.div`
  backdrop-filter: blur(8px); 
  z-index: 100;
  background-color: rgba(5, 71, 41, 0.2); /* Color con 20% de opacidad */
  border-radius: 16px;
  padding: 30px;
  min-width: 300px;
  height: 350px; /* Tamaño fijo para las tarjetas */
  text-align: center;
  box-shadow: 0 0 3px 0px rgba(255, 255, 255, 1); /* Borde borroso */
  overflow: hidden; /* Recorta el contenido que excede */
  margin: 0 10px; /* Espacio entre tarjetas */

  @media (min-width: 768px) {
    width: 500px; /* Ancho fijo en escritorio */
  }
`;

const Title = styled.h2`
  margin-top: 10px;
  font-family: 'Kanit-SemiBold', sans-serif;
  color: white;

  @media (min-width: 768px) {
    margin-top: 30px;
  }
`;

const Image = styled.img`
  width: 50px;
  margin: 20px auto;
`;

const Text = styled.p`
  font-family: 'Kanit-ExtraLight', sans-serif;
  color: white;
  margin: 20px auto;
`;

const titles = ["Reducción de riesgos", "Innovación centrada en el usuario", "Resultados tangibles"];

const texts = [
  "Probar y ajustar nuestras ideas en fases tempranas nos permite identificar y mitigarriesgos, ahorrando tiempo y recursos.",
  "Creamos soluciones que resuenan profundamente con tus usuarios, aumentando la satisfacción y la lealtad",
  "Aseguramos que cada solución no solo sea creativa, sino también viable y efectiva en el mundo real.",
];

const TituloPrincipal = () => {
  const cardContainerRef = useRef(null);
  const isDownRef = useRef(false);
  const startXRef = useRef(0);
  const scrollLeftRef = useRef(0);
  const [isScrollable, setIsScrollable] = useState(window.innerWidth < 1650);

  useEffect(() => {
    const handleResize = () => {
      setIsScrollable(window.innerWidth < 1650);
    };
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const handleMouseDown = (e) => {
    if (!isScrollable) return;
    isDownRef.current = true;
    startXRef.current = e.pageX - cardContainerRef.current.offsetLeft;
    scrollLeftRef.current = cardContainerRef.current.scrollLeft;
    cardContainerRef.current.style.cursor = 'grabbing';
  };

  const handleMouseLeave = () => {
    if (!isScrollable) return;
    isDownRef.current = false;
    cardContainerRef.current.style.cursor = 'grab';
  };

  const handleMouseUp = () => {
    if (!isScrollable) return;
    isDownRef.current = false;
    cardContainerRef.current.style.cursor = 'grab';
  };

  const handleMouseMove = (e) => {
    if (!isScrollable || !isDownRef.current) return;
    e.preventDefault();
    const x = e.pageX - cardContainerRef.current.offsetLeft;
    const walk = x - startXRef.current;
    cardContainerRef.current.scrollLeft = scrollLeftRef.current - walk;
  };

  // Manejadores de eventos de toque
  const handleTouchStart = (e) => {
    if (!isScrollable) return;
    isDownRef.current = true;
    startXRef.current = e.touches[0].pageX - cardContainerRef.current.offsetLeft;
    scrollLeftRef.current = cardContainerRef.current.scrollLeft;
  };

  const handleTouchEnd = () => {
    if (!isScrollable) return;
    isDownRef.current = false;
  };

  const handleTouchMove = (e) => {
    if (!isScrollable || !isDownRef.current) return;
    const x = e.touches[0].pageX - cardContainerRef.current.offsetLeft;
    const walk = x - startXRef.current;
    cardContainerRef.current.scrollLeft = scrollLeftRef.current - walk;
  };

  return (
    <CardContainer
      ref={cardContainerRef}
      isScrollable={isScrollable}
      onMouseDown={handleMouseDown}
      onMouseLeave={handleMouseLeave}
      onMouseUp={handleMouseUp}
      onMouseMove={handleMouseMove}
      onTouchStart={handleTouchStart}
      onTouchEnd={handleTouchEnd}
      onTouchMove={handleTouchMove}
    >
      <CardWrapper>
        {titles.map((title, index) => (
          <Card key={index}>
            <Title>{title}</Title>
            <Image src={StarImage} alt="Star" />
            <Text>{texts[index]}</Text>
          </Card>
        ))}
      </CardWrapper>
    </CardContainer>
  );
};

export default TituloPrincipal;
