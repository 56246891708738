import React from 'react';
import styled from 'styled-components';

// Contenedor principal
const InfoContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 2vw 8vw;
  z-index: 10;
  text-align: center; 
  overflow: hidden;


  @media (max-width: 768px) {
    flex-direction: column; /* Cambiar a columna en móviles */
    padding: 5vw 5vw;
  }
`;

// Contenedor de cada bloque de texto
const TextBlock = styled.div`
  flex: 1;
  padding: 0 2vw;
  margin-bottom: 0vw; /* Espaciado entre bloques */

  @media (max-width: 768px) {
    position: relative; /* Cambiar a relativo en móviles */
    width: 100%;
    margin-bottom: 4vw; /* Espaciado entre bloques */
  }
`;

// Estilos para el título en negrita
const Bold = styled.strong`
  font-family: 'Kanit-SemiBold', sans-serif;
  font-size: 1.2vw;
  line-height: 1;
  color: white;

  @media (max-width: 768px) {
    font-size: 5vw;
    margin-top: 1vw;
  }
`;

// Estilos para el texto de párrafo
const Texto = styled.p`
  font-family: 'Kanit-Light', sans-serif;
  color: white;
  margin: 0;
  font-size: 1vw;
  line-height: 1.2;
  margin-top: 1vw;

  @media (max-width: 768px) {
    margin-top: 3vw;
    font-size: 4vw;
  }
`;

// Componente principal
const TituloPrincipal = () => {
  

  const texts = [
    {
      title: "Velocidad y optimización",
      content: "Hacemos que tu web cargue en menos de 3 segundos. Porque cada segundo cuenta, y tus clientes no tienen paciencia para webs lentas. No les des excusas para irse a la competencia."
    },
    {
      title: "Webs que convierten",
      content: "Cada elemento de tu web está diseñado para una sola cosa: que el usuario tome acción. Ya sea agendar una llamada, comprar, o dejar sus datos. Todo está pensado para convertir visitas en clientes."
    },
    {
      title: "100% adaptada a móviles",
      content: "Más del 70% de tus usuarios navegan desde el móvil. Si tu web no se adapta perfectamente, pierdes la mitad de tu mercado. Así de simple. Nos aseguramos de que eso no te pase."
    },
    {
      title: "SEO optimizado desde el primer día",
      content: "No dejamos que tu web pase desapercibida en Google. Desde el momento en que lanzamos tu web, está preparada para escalar en los motores de búsqueda. Tienes que estar en el radar de tus clientes ahora."
    }
  ];
  

  return (
    <InfoContainer>
      {texts.map((text, index) => (
        <TextBlock key={index}>
          <Bold>{text.title}</Bold>
          <Texto>{text.content}</Texto>
        </TextBlock>
      ))}
    </InfoContainer>
  );
};

export default TituloPrincipal;