import React, { useState } from "react";
import styled from 'styled-components';
import "./style.css";
import { Link } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';
import TextoInfinito from "../../components/TextoInfinito/textoinfinito";
import Fluid3D4 from './Fluid 3D 5.png';

const Logo = styled.img`


  @media (max-width: 768px) {
    opacity: 0.2;
    height: 70px;
    margin-left: 20px;
    margin-bottom: 20px;
    margin-top: -60px;
  }
`;


const TextoInfinitoContainer = styled.div`
z-index: -1;
  position: absolute;
  bottom: 50px; /* Espacio por encima del footer */
  width: 100%;
  display: flex;
  justify-content: center;
  @media (max-width: 768px) {
    bottom: 60px; /* Ajuste para dispositivos móviles */
  }
`;

const Image = styled.img`
  width: 100%;
  height: auto; /* Maintain aspect ratio */
  position: absolute;
  opacity: 0.6;
  z-index: -1; 
`;


const Image4 = styled(Image)`


  @media (max-width: 768px) {
    z-index: -1;
    top: 0%;
  }
`;

const BackgroundImagesContainer = styled.div`

  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: -1;

`;



const Headermain = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [isActive, setIsActive] = useState(false); // Nuevo estado para manejar la clase active

  const toggleMenu = () => {
    setIsOpen(!isOpen);

    const scrollbarWidth = window.innerWidth - document.documentElement.clientWidth;

    if (!isOpen) {
      document.body.style.paddingRight = `${scrollbarWidth}px`;
      document.querySelector('.burger-button').style.right = `${25 + scrollbarWidth}px`; // Asumiendo que el valor original es 25px
      document.body.classList.add("ovhidden");
    } else {
      document.body.style.paddingRight = '';
      document.querySelector('.burger-button').style.right = `25px`; // Restaurar a la posición original
      document.body.classList.remove("ovhidden");
    }

    setIsActive(true);


    setTimeout(() => {
      setIsActive(false);
    }, 2000);
  };
  return (
    <>
      <header className="fixed-top site__header">
        <button className={`burger-button ${isOpen ? 'open' : ''} ${isActive ? 'active' : ''}`} onClick={toggleMenu}>
          <span></span>
          <span></span>
          <span></span>
        </button>

        <div className={`site__navigation ${isOpen ? "menu__opend" : ""}`}>
          <div className="bg__menu h-100">
            <div className="menu__wrapper">

              <ul className="the_menu">

                <Logo src="/Logo.png" alt="Logo" />

                <li className="menu_item">
                  <Link onClick={toggleMenu} to="/" className="my-3">INICIO</Link>
                </li>

                <li className="menu_item">
                <HashLink onClick={toggleMenu} smooth to="/home#work" className="my-3">SOLUCIONES</HashLink>
                </li>

                <li className="menu_item">
  <HashLink onClick={toggleMenu} smooth to="/home#about-us">ABOUT US</HashLink>
</li>

                <BackgroundImagesContainer>
            <Image4 src={Fluid3D4} alt="Background Image 4" />
          </BackgroundImagesContainer>

                <li className="menu_item">
                  <Link onClick={toggleMenu} to="/contacto" className="my-3">CONTACTO</Link>
                </li>
              </ul>

              <TextoInfinitoContainer>
                <TextoInfinito />
              </TextoInfinitoContainer>

            </div>
          </div>



          <div className="menu_footer d-flex flex-column flex-md-row justify-content-between align-items-md-center position-absolute w-100 p-3">

            <p className="copyright m-0">
              © 2024 Gather Impulse. Todos los derechos reservados.
            </p>
          </div>
        </div>

      </header>
      <div className="br-top"></div>
      <div className="br-bottom"></div>
      <div className="br-left"></div>
      <div className="br-right"></div>
    </>
  );
};

export default Headermain;
