import React from 'react';
import styled from 'styled-components';



const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 3vw 9vw;
  position: relative;
  z-index: 10;

  @media (max-width: 768px) {
    padding-top: 10vw;
  }
`;

const Titulo = styled.p`
  font-family: 'Kanit-SemiBold', sans-serif;
  color: white;
  margin: 0;
  padding: 0;
  width: 67%; /* Ajustar el ancho del contenedor */
  font-size: 2.5vw; /* Tamaño de fuente ajustable */
  line-height: 1.2; /* Ajustar la altura de línea según sea necesario */
  @media (max-width: 768px) {
    font-size: 6.5vw; /* Tamaño de fuente ajustable */
    line-height: 1.2;
    width: 100%; /* Ajustar el ancho del contenedor */
  }

`;



const Subtitulo = styled.p`
  font-family: 'Kanit-SemiBold', sans-serif;
  color: white;
  margin: 0;
  padding-top:30px;
  width: 100%; /* Ajustar el ancho del contenedor */
  font-size: 2.6vw; /* Tamaño de fuente ajustable */
  line-height: 1.2; /* Ajustar la altura de línea según sea necesario */
  @media (max-width: 768px) {
    font-size: 6vw;
    line-height: 1.2;
  }

`;

const ExtraLightSpan = styled.span`
  font-family: 'Kanit-ExtraLight', sans-serif;
  
  @media (max-width: 768px) {
    font-size: 5.5vw; 
    
  }

`;






const TituloPrincipal = () => {
  return (
    <InfoContainer>


      <Titulo>
        Investigar, proponer, colaborar <ExtraLightSpan>son palabras que definen gran parte de nuestro trabajo.</ExtraLightSpan>
      </Titulo>


      <Subtitulo>
        Sin diseño, no hay experiencia.
      </Subtitulo>




    </InfoContainer>
  );
};

export default TituloPrincipal;
