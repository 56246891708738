import React from 'react';
import styled from 'styled-components';

const InfoContainer = styled.div`

 
  padding: 8vw 9vw;
margin-top: 80px;

  @media (max-width: 768px) {
  
    margin-top: 100px;
    align-items: flex-end; 
  }
`;






const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  @media (max-width: 768px) {
    align-items: flex-end;
  }
`;

const Titulo = styled.p`
  font-family: 'Kanit-SemiBold', sans-serif;
  color: white;
  margin: 0;
  padding: 0;
  width: 70%; /* Ajustar el ancho del contenedor */
  font-size: 3vw; /* Tamaño de fuente ajustable */
  line-height: 1.2; /* Ajustar la altura de línea según sea necesario */
  text-align: right; /* Alinear el texto a la derecha */
  
  @media (max-width: 768px) {
    font-size: 5vw;
    width: 80%; /* Ajustar el ancho del contenedor */
  }
`;

const Texto = styled.p`
  font-family: 'Kanit-ExtraLight', sans-serif;
  color: white;
  margin-top: 40px;
  padding: 0;
  width: 46%; /* Ajustar el ancho del contenedor */
  font-size: 1.9vw; /* Tamaño de fuente ajustable */
  line-height: 1.2; /* Ajustar la altura de línea según sea necesario */
  text-align: right; /* Alinear el texto a la derecha */

  @media (max-width: 768px) {
    font-size: 3.7vw; 
    margin-top: 1px;
    width: 84%; /* Ajustar el ancho del contenedor */
  }
`;




const TituloDesign = () => {
  return (
    <InfoContainer>
  
      <TextContainer>
        <Titulo> DESIGN THINKING </Titulo>
        <Texto>
          Optimiza el flujo de trabajo de tus proyectos mediante la mejora del proceso de diseño y una colaboración más eficiente
        </Texto>
      </TextContainer>
    </InfoContainer>
  );
};

export default TituloDesign;
