import React from 'react';
import Menu from "../../components/Menu/menu";
import Header from "../../components/Header/header";
import styled from 'styled-components';
import TituloContacto from "../../components/Contacto/ContactoTitulo/ContactoTitulo";
import FormularioContacto from "../../components/Contacto/FormularioContacto/FormularioContacto";
import exampleImg from './Fluid.png'; 

const BackgroundImages = styled.div`
z-index: 2;
  position: relative; 
  width: 100%;
  height: 100vh; 
  overflow-y: auto;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #0A0A0A;
`;

const ImageContainer = styled.div`

  position: absolute;
  top: 0px ; 
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0.7;
  background-image: url(${exampleImg});
  background-size: cover; // Asegura que la imagen cubra todo el contenedor
  background-repeat: no-repeat; // Evita que la imagen se repita


  @media (max-width: 768px) {
    top: 460px;
    width: 150%;
    right: 0;
    background-size: contain; // Asegura que la imagen se vea completa en dispositivos móviles
  }
`;

const BlackOverlay = styled.div`
 pointer-events: none;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: black;
  z-index: 1000;
  animation: fadeOut 0.7s ease-in-out forwards;

  @keyframes fadeOut {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
      display: none;
    }
  }
`;

const VistaContacto = () => {
  return (
    <BackgroundImages>
         <BlackOverlay />
      <Menu />
      <Header />
      
        <ImageContainer /> 
        
      <TituloContacto />
      <FormularioContacto />
    
    </BackgroundImages>
  );
};

export default VistaContacto;
