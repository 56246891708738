import React from 'react';
import styled from 'styled-components';
import Menu from "../../components/Menu/menu";
import Header from "../../components/Header/header";


const BlackOverlay = styled.div`

  pointer-events: none;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: black;
  z-index: 1000;
  animation: fadeOut 0.7s ease-in-out forwards;

  @keyframes fadeOut {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
      display: none;
    }
  }
`;
const Contenedor = styled.div`
  width: 100%;
  background-color: #0A0A0A;
  justify-content: center;
  padding-top: 100px;

`;

const LegalNotice = styled.div`


  padding: 2vw 19vw;
  background-color: #0a0a0a;
  color: #000000;
  font-family: 'Arial', sans-serif;
  font-size: 1vw;
  line-height: 1.5;
  color: white;
 


  @media (max-width: 768px) {
    font-size: 3vw;
    padding: 2vw 6vw;
  }
`;


const Home = () => {
  return (
    <>
      <Menu />
      <Header />
      <BlackOverlay />



      <Contenedor>
        <LegalNotice>


          <h1>Aviso Legal</h1>
          <p>
            Este Aviso Legal tiene el objetivo de informarte de tus derechos y obligaciones como usuario de este
            sitio web. Aquí encontrarás toda la información que necesitas sobre este sitio web, su actividad, los
            datos personales que recaba y su finalidad, así como las normas de uso que regulan el uso de esta
            web.
          </p>
          <p>
            En el momento en que accedas a este sitio web <a href="https://gatherimpulse.com/" target="_blank"  rel="noreferrer">https://gatherimpulse.com/</a>
            , asumes la condición de
            usuario, por lo que el contenido de este Aviso Legal te afecta directamente. Por eso, es importante
            que lo leas para disipar cualquier duda que puedas tener y tener conocimiento de causa sobre las
            condiciones que estás aceptando.
          </p>
          <p>
            Para empezar, debes saber que este sitio web cumple con las normativas vigentes en materia de
            protección de datos, con el objetivo de aportarte las garantías, la seguridad y la transparencia que,
            como usuario te corresponden, a la hora de utilizar esta web.
          </p>
          <p>
            El RGPD (Reglamento (UE) 2016/679 del Parlamento Europeo y del Consejo de 27 de abril de 2016
            relativo a la protección de las personas físicas) que es la nueva normativa de la Unión Europea que
            unifica la regulación del tratamiento de los datos personales en los distintos países de la UE.
          </p>
          <p>
            La LOPD (Ley Orgánica 15/1999, de 13 de diciembre, de Protección de Datos de Carácter Personal y
            Real Decreto 1720/2007, de 21 de diciembre, el Reglamento de desarrollo de la LOPD) que regula el
            tratamiento de los datos personales y las obligaciones que debemos asumir los responsables de una
            web o un blog a la hora de gestionar esta información.
          </p>
          <p>
            La LSSI (Ley 34/2002, de 11 de julio, de Servicios de la Sociedad de la Información y Comercio
            Electrónico) que regula las transacciones económicas mediante medios electrónicos, como es el
            caso de este blog.
          </p>
          <h2>Datos de Identificación</h2>
          <p>
            El responsable y titular de este sitio web es ALEX PÉREZ (En adelante ALEXKAH)
          </p>
          <p>
            <strong>Nombre:</strong> ALEX PÉREZ<br />
            <strong>Domicilio Social:</strong> MAR MENOR 7 – SEVILLA<br />
            <strong>Actividad del sitio web:</strong> Servicios de marketing, diseño y gestión en RRSS, muestra de
            publicidad, y recomendación de productos de afiliado.<br />
            <strong>Correo electrónico:</strong> info@gatherimpulse.com
          </p>
          <p>
            Los datos que nos facilites con tu consentimiento, y de acuerdo al uso establecido en nuestra Política
            de Privacidad, serán incorporados a un fichero automatizado debidamente inscrito en la Agencia
            Española de Protección de Datos, en el que el responsable de dicho fichero es: ALEXKAH. Esto quiere
            decir que tus datos están seguros, de acuerdo a lo que establece la ley.
          </p>




          <h2>Normas de Acceso al Sitio Web</h2>
          <p>
            Como usuario de nuestra web, también tienes una serie de obligaciones:
            No podrás utilizar este sitio web para la realización de actividades contrarias a las leyes, a la moral, al
            orden público y, en general, a hacer un uso conforme a las condiciones establecidas en el presente
            Aviso Legal.
          </p>
          <p>
            No podrás realizar actividades publicitarias o de explotación comercial remitiendo mensajes que
            utilicen una identidad falsa.
          </p>
          <p>
            Tú serás el único responsable de la veracidad y exactitud de los contenidos que introduzcas en esta
            web y de los datos personales que nos facilites con las finalidades previstas en nuestra Política de
            Privacidad.
          </p>
          <p>
            También serás el único responsable de la realización de cualquier tipo de actuación ilícita, lesiva de
            derechos, nociva y/o perjudicial en los sitios de terceros a los que te podamos derivar desde este
            sitio web para el desarrollo de nuestra actividad.
          </p>
          <p>
            Como responsable del sitio web, ALEXKAH podrá interrumpir el servicio de la página que esté siendo
            utilizado por el usuario y resolver de modo inmediato la relación si detecta un uso de la web o de
            cualquiera de los servicios que en la mismo se ofertan que pueda considerarse contrario a lo
            expresado en el presente Aviso Legal.
          </p>

          <h2>Propiedad Intelectual e Industrial</h2>
          <p>
            La totalidad de este sitio web (texto, imágenes, marcas, gráficos, logotipos, botones, archivos de
            software, combinaciones de colores, así como la estructura, selección, ordenación y presentación de
            sus contenidos) se encuentra protegida por las leyes vigente sobre Propiedad Intelectual e Industrial,
            quedando prohibida su reproducción, distribución, comunicación pública y transformación, salvo para
            uso personal y privado.
          </p>
          <p>
            Como titular de este sitio web, ALEXKAH no garantiza que los contenidos sean precisos o libres de
            error o que el libre uso de los mismos por parte de los usuarios no infrinja los derechos de terceras
            partes. El buen o mal uso de esta página y de sus contenidos es responsabilidad del usuario.
          </p>
          <p>
            Asimismo, queda prohibida la reproducción, retransmisión, copia, cesión o redifusión, total o parcial,
            de la información contenida en la página, cualquiera que fuera su finalidad y el medio utilizado para
            ello, sin autorización previa de ALEXKAH.
          </p>

          <h2>Enlaces o Links</h2>
          <p>
            Este sitio web incluye enlaces o links a sitios de terceros. Las páginas pertenecientes a estos
            terceros no han sido revisadas ni son objeto de controles por nuestra parte, por lo que ALEXKAH no
            podrá ser considerado responsable de los contenidos de estos sitios web, ni de las medidas que se
            adopten relativas a su privacidad o al tratamiento de sus datos de carácter personal u otros que
            pudieran derivarse.
          </p>
          <p>
            Por todo ello, te recomendamos la lectura detenida de las condiciones de uso, política de privacidad,
            avisos legales y/o similares de estos sitios.
          </p>

          <h2>Enlaces de Afiliación de Amazon</h2>
          <p>
            Este sitio web, de acuerdo a su finalidad, utiliza enlaces de afiliado de Amazon.
            Esto quiere decir que encontrarás enlaces de productos de Amazon a los que podrás acceder
            directamente desde nuestro sitio web pero que, en su caso, la compra la realizarás en Amazon, bajo
            sus propias condiciones en ese momento.
          </p>

          <h2>Limitación de Responsabilidad</h2>
          <p>
            En ejercicio de su derecho como titular de esta web, te informamos de que ALEXKAH no se hace
            responsable en ningún caso de lo siguiente:
          </p>
          <ul>
            <li>La calidad del servicio, la velocidad de acceso, el correcto funcionamiento ni la disponibilidad ni
              continuidad de funcionamiento de la página.</li>
            <li>La existencia de virus, malware, programas maliciosos o dañinos en los contenidos.</li>
            <li>El uso ilícito, negligente, fraudulento o contrario a este Aviso Legal.</li>
            <li>La falta de licitud, calidad, fiabilidad, utilidad y disponibilidad de los servicios prestados por terceros y
              puestos a disposición de los usuarios en este sitio web.</li>
            <li>De los daños que pudieran provenir del uso ilegal o indebido de este blog.</li>
          </ul>


          <h2>Política de Privacidad y Protección de Datos</h2>
          <p>
            Este sitio web cumple con la normativa vigente en materia de protección de datos, lo que implica que,
            como usuario, deberás dar tu consentimiento expreso antes de facilitarnos datos personales a través
            de los diferentes formularios puestos a disposición en las secciones de nuestra página.
          </p>
          <p>
            Para ello, en aras de la transparencia y el ejercicio de tu derecho, nuestro deber es informarte sobre
            los datos personales que recabamos, almacenamos y tratamos y con qué finalidades, teniendo en
            cualquier momento la posibilidad de revocar libremente tu consentimiento.
          </p>
          <p>
            Toda esta información la podrás encontrar en nuestra <strong>POLÍTICA DE PRIVACIDAD</strong>.
          </p>

          <h2>Política de Cookies</h2>
          <p>
            Tal y como te informamos nada más acceder a nuestra web, este sitio utiliza cookies propias y de
            terceros con el objetivo de proporcionarte la mejor experiencia de usuario y desarrollar nuestra
            actividad.
          </p>
          <p>
            En cualquier momento, tendrás la opción de configurar tu navegador para desestimar el uso de estas
            cookies que, en algún caso, afectarán a tu experiencia de usuario.
          </p>
          <p>
            Para acceder a la información completa sobre el uso de cookies en este sitio web, su finalidad y su
            desestimación, puedes consultar nuestra <strong>POLÍTICA DE COOKIES</strong>.
          </p>

          <h2>Legislación Aplicable y Jurisdicción Competente</h2>
          <p>
            El presente Aviso Legal se encuentra sometido a la legislación española vigente.
          </p>
          <p>
            En caso necesario, ante cualquier tipo de controversia de carácter legal, ALEXKAH y el usuario, con
            renuncia expresa a cualquier otro fuero, se someterán a los Juzgados y Tribunales del domicilio del
            Usuario para cualquier controversia que pudiera derivarse.
          </p>
          <div id="privacidad">   </div>
          <p>
            En el caso de que el usuario tenga su domicilio fuera de España, ALEXKAH y el usuario se someterán,
            con renuncia expresa a cualquier otro fuero, a los juzgados y tribunales de Barcelona (España).
          </p>
          <p>
            Si tienes cualquier duda sobre este Aviso Legal, puedes enviarnos un correo electrónico a 
          <a href="mailto:info@gatherimpulse.com" target="_blank" rel="noreferrer">info@gatherimpulse.com</a>





.
          </p>




          <h2>Privacidad</h2>

          <p>
            Si estás aquí es porque quieres saber más sobre las obligaciones y derechos que te corresponden
            como usuario de esta web <a href="https://gatherimpulse.com">https://gatherimpulse.com</a> y eso está muy bien. Nuestro deber es
            informarte y el tuyo estar debidamente informado.
          </p>
          <p>
            En esta Política de Privacidad te informaremos con total transparencia sobre la finalidad de este sitio
            web y todo lo que afecta a los datos que nos facilites, así como de las obligaciones y derechos que te
            corresponden.
          </p>
          <p>
            Para empezar, debes saber que este sitio web se adapta a la normativa vigente en relación con la
            protección de datos, lo que afecta a los datos personales que nos facilites con tu consentimiento
            expreso y a las cookies que utilizamos para que este sitio web funcione correctamente y pueda
            desarrollar su actividad.
          </p>
          <p>
            Concretamente, esta web se ajusta al cumplimiento de las siguientes normativas:
          </p>
          <ul>
            <li>El RGPD (Reglamento (UE) 2016/679 del Parlamento Europeo y del Consejo de 27 de abril de 2016
              relativo a la protección de las personas físicas) que es la nueva normativa de la Unión Europea que
              unifica la regulación del tratamiento de los datos personales en los distintos países de la UE.
            </li>
            <li>La LOPD (Ley Orgánica 15/1999, de 13 de diciembre, de Protección de Datos de Carácter Personal y
              Real Decreto 1720/2007, de 21 de diciembre, el Reglamento de desarrollo de la LOPD) que regula el
              tratamiento de los datos personales y las obligaciones que debemos asumir los responsables de una
              web o un blog a la hora de gestionar esta información.
            </li>
            <li>La LSSI (Ley 34/2002, de 11 de julio, de Servicios de la Sociedad de la Información y Comercio
              Electrónico) que regula las transacciones económicas mediante medios electrónicos, como podría
              ser el caso de este sitio web.
            </li>
          </ul>

          <h2>Datos de Identificación</h2>
          <p>
            El responsable y titular de este sitio web es ALEX PÉREZ (En adelante ALEXKAH)
          </p>
          <ul>
            <li>Nombre: ALEX PÉREZ</li>
            <li>Domicilio: MAR MENOR 7. 41015 – SEVILLA</li>
            <li>Actividad del sitio web: Servicios de marketing, diseño y gestión en RRSS, muestra de publicidad, y recomendación de productos de afiliado.</li>
            <li>Correo electrónico: <a href="mailto:info@gatherimpulse.com">info@gatherimpulse.com</a></li>
          </ul>
          <p>
            Los datos que nos facilites con tu consentimiento, y de acuerdo al uso establecido en esta Política de
            Privacidad, serán incorporados a un fichero automatizado debidamente inscrito en la Agencia
            Española de Protección de Datos, en el que el responsable de dicho fichero es: ALEXKAH. Esto quiere
            decir que tus datos están seguros, de acuerdo a lo que establece la ley.
          </p>
          <p>
            Los datos personales que nos facilites, siempre con tu consentimiento expreso, se almacenarán y
            tratarán con los fines previstos y descritos a continuación en esta Política de Privacidad, hasta el
            momento en que nos pidas que los eliminemos.
          </p>
          <p>
            Te informamos de que esta Política de Privacidad puede ser modificada en cualquier momento, con
            el fin de adaptarla a novedades legislativas o cambios en nuestras actividades, siendo vigente la que
            en cada momento se encuentre publicada en la web. Dicha modificación se te notificará antes de su
            aplicación.
          </p>
          <h2>Condiciones de Uso</h2>
          <p>
            Debes saber, para tu tranquilidad, que siempre te solicitaremos tu consentimiento expreso para
            recabar tus datos con la correspondiente finalidad especificada en cada caso, lo que implica que, en
            caso de otorgar ese consentimiento, has leído y aceptado esta Política de Privacidad.
          </p>
          <p>
            En el momento en que accedas y utilices esta web, asumes tu condición de usuario con tus
            correspondientes derechos y obligaciones.
          </p>
          <p>
            Si eres mayor de 13 años, podrás registrarte como usuario en este sitio web sin el previo
            consentimiento de tus padres o tutores.
          </p>
          <p>
            Si eres menor de 13 años, necesitarás el consentimiento de tus padres o tutores para el tratamiento
            de tus datos personales.
          </p>







          <h2>Registro y Finalidad de tus Datos</h2>
          <p>
            En función del formulario o sección a la que accedas, te solicitaremos exclusivamente los datos necesarios con las finalidades descritas a continuación. En todo momento, deberás dar tu consentimiento expreso, cuando te solicitamos información personal con las siguientes finalidades:
          </p>
          <ul>
            <li>Las finalidades concretas que particularmente se indiquen en cada una de las páginas o secciones donde aparezca el formulario de registro o contacto electrónico.</li>
            <li>Con carácter general, para atender tus solicitudes, comentarios, consultas o cualquier tipo de petición que realices como usuario a través de cualquiera de las formas de contacto que ponemos a tu disposición.</li>
            <li>Para informarte sobre consultas, peticiones, actividades, productos, novedades y/o servicios; vía e-mail, fax, Whatsapp, Skype, teléfono proporcionado, sms y mms.</li>
            <li>Para enviarte comunicaciones comerciales o publicitarias a través de cualquier otro medio electrónico o físico, que posibilite realizar comunicaciones.</li>
          </ul>
          <p>
            Estas comunicaciones siempre serán relacionadas con nuestros productos, servicios, novedades o promociones, así como aquellos productos o servicios que podamos considerar de tu interés y que puedan ofrecer colaboradores, empresas o "partners" con los que mantengamos acuerdos de promoción o colaboración comercial.
          </p>
          <p>
            De ser así, te garantizamos que estos terceros nunca tendrán acceso a tus datos personales, con las excepciones reflejadas a continuación, siendo en todo caso estas comunicaciones realizadas por parte de ALEXKAH, como titular de la web.
          </p>
          <p>
            Esto quiere decir que al hacer clic en “Comprar Ahora” o similar, se te redirigirá a la página donde se ofertan los productos.
          </p>
          <p>
            En este caso, debes saber que únicamente proporcionamos y facilitamos los enlaces a las páginas y/o plataformas de estos terceros donde pueden adquirirse los productos que mostramos, en aras a facilitar la búsqueda y fácil adquisición de los mismos.
          </p>
          <p>
            Estas páginas enlazadas y pertenecientes a terceros no han sido revisadas ni son objeto de controles, ni recomendación por nuestra parte, por lo que en ningún caso ALEXKAH será considerada responsable de los contenidos de estos sitios web, de las responsabilidades derivadas de su uso en todos los ámbitos, ni por las medidas que se adopten relativas a la privacidad del usuario, tratamiento de sus datos de carácter personal u otras que pudieran establecerse.
          </p>
          <p>
            Por todo ello, te recomendamos la lectura detenida y anticipada de todas las condiciones de uso, condiciones de compra, políticas de privacidad, avisos legales y/o similares de estos sitios enlazados antes de proceder a la adquisición de estos productos o uso de las webs.
          </p>

          <h2>Exactitud y Veracidad de los Datos</h2>
          <p>
            Como usuario, eres el único responsable de la veracidad y modificación de los datos que remitas a ALEXKAH, exonerándonos de cualquier responsabilidad al respecto.
          </p>
          <p>
            Esto implica que tú garantizas y respondes en cualquier caso de la exactitud, vigencia y autenticidad de los datos personales facilitados, comprometiéndote a mantenerlos debidamente actualizados.
          </p>
          <p>
            De acuerdo con lo establecido en esta Política de Privacidad, aceptas proporcionar información completa y correcta en el formulario de contacto o suscripción.
          </p>

          <h2>Bajas de Suscripción y Derecho de Revocación</h2>
          <p>
            Como titular de los datos que nos hayas facilitado, podrás ejercer en cualquier momento tus derechos de acceso, rectificación, cancelación y oposición, enviándonos un correo electrónico a info@gatherimpulse.com y adjuntando una fotocopia de tu documento de identidad como prueba válida.
          </p>
          <p>
            Igualmente, podrás darte de baja en cualquier momento para dejar de recibir nuestra newsletter o cualquier otra comunicación comercial, directamente desde el mismo email que hayas recibido o enviándonos un correo a info@gatherimpulse.com.
          </p>

          <h2>Acceso a Datos por Cuenta de Terceros</h2>
          <p>
            Para poder prestar servicios estrictamente necesarios para el funcionamiento y desarrollo de las actividades de este sitio web, te informamos de que compartimos datos con los siguientes prestadores de servicio bajo sus correspondientes condiciones de privacidad.
          </p>
          <p>
            Puedes tener la total tranquilidad de que estos terceros no podrán utilizar dicha información para ningún otro fin que esté regulado específicamente en nuestras relaciones con ellos en virtud de la normativa aplicable en materia de protección de datos de carácter personal.
          </p>
          <p>
            Nuestra web utiliza servidores publicitarios con el fin de facilitar los contenidos comerciales que visualizas en nuestras páginas. Dichos servidores publicitarios utilizan cookies que le permiten adaptar los contenidos publicitarios a los perfiles demográficos de los usuarios:
          </p>
          <p>
            <strong>Google Analytics:</strong> Google Analytics es un servicio analítico de web prestado por Google, Inc., una compañía de Delaware cuya oficina principal está en 1600 Amphitheatre Parkway, Mountain View (California), CA 94043, Estados Unidos (“Google”). Google Analytics utiliza “cookies”, que son archivos de texto ubicados en tu ordenador, para ayudar al website a analizar el uso que hacen los usuarios del sitio web. La información que genera la cookie acerca de tu uso del website (incluyendo tu dirección IP) será directamente transmitida y archivada por Google. Google usará esta información por cuenta nuestra con el propósito de seguir la pista de su uso del website, recopilando informes de la actividad del website y prestando otros servicios relacionados con la actividad del website y el uso de Internet. Google podrá transmitir dicha información a terceros cuando así se lo requiera la legislación, o cuando dichos terceros procesen la información por cuenta de Google. Google no asociará su dirección IP con ningún otro dato del que disponga.
          </p>
          <p>
            Como usuario, y en ejercicio de tus derechos, puedes rechazar el tratamiento de los datos o la información rechazando el uso de cookies mediante la selección de la configuración apropiada de tu navegador, sin embargo, debes saber que si lo hace puede que no puedas usar la plena funcionalidad de este sitio web. Al utilizar esta web, de acuerdo a la información facilitada en esta Política de Privacidad, aceptas el tratamiento de datos por parte de Google en la forma y para los fines indicados. Para más información, puedes consultar la política de privacidad de Google en <a href="https://www.google.com/intl/es/policies/privacy/" target="_blank" rel="noreferrer">https://www.google.com/intl/es/policies/privacy/</a>.
          </p>

          <p>
            <strong>Google Adsense: </strong>

            Google, como proveedor asociado, utiliza cookies para publicar anuncios en este sitio web. Puedes inhabilitar el uso de la cookie de DART a través del anuncio de Google y accediendo a la política de privacidad de la red de contenido: <a href="https://www.google.com/intl/es/policies/privacy/" target="_blank" rel="noreferrer">https://www.google.com/intl/es/policies/privacy/</a>.
          </p>
          <p>
            Google utiliza empresas publicitarias asociadas para publicar anuncios cuando visita nuestro sitio web. Es posible que estas empresas usen la información que obtienen de tus visitas a este y otros sitios web (sin incluir tu nombre, dirección, dirección de correo electrónico o número de teléfono) para ofrecerte anuncios sobre productos y servicios que te resulten de interés.
          </p>
          <p>
            Al utilizar esta web, consientes el tratamiento de datos por parte de Google en la forma y para los fines indicados.
          </p>




          <h2>Medidas de Seguridad</h2>
          <div id="cookie">   </div>
          <p>
            Como titular de la web, ALEXKAH ha adoptado todas las medidas técnicas y de organización necesaria para garantizar la seguridad e integridad de los datos de carácter personal que trate, así como para evitar su pérdida, alteración y/o acceso por parte de terceros no autorizados.
          </p>


          <h2>Cookies</h2>
          <p>
            Si quieres saber más sobre el uso de cookies que realiza este sitio web <a href="https://gatherimpulse.com" target="_blank" rel="noreferrer">https://gatherimpulse.com</a>, estás en el lugar indicado. A continuación, vamos a explicarte qué son exactamente las cookies; qué tipo de cookies utilizamos y para qué; y cómo puedes ejercer tu derecho para configurar tu navegador y desestimar el uso de cualquiera de ellas.
          </p>
          <p>
            Eso sí, debes saber que si decides no utilizar algunas cookies, este sitio web puede no funcionar perfectamente, afectando a tu experiencia de usuario.
          </p>

          <h2>¿Que es una cookie?</h2>
          <p>
            Una cookie es un fichero que se descarga en tu ordenador al acceder a determinadas páginas web o blogs.
          </p>
          <p>
            Las cookies permiten a esa página, entre otras cosas, almacenar y recuperar información sobre tus hábitos de navegación o de tu equipo, y dependiendo de la información que contengan y de la forma en que utilices tu equipo, pueden utilizarse para reconocerte.
          </p>
          <p>
            El navegador del usuario memoriza cookies en el disco duro solamente durante la sesión actual ocupando un espacio de memoria mínimo y no perjudicando al ordenador. Las cookies no contienen ninguna clase de información personal específica, y la mayoría de las mismas se borran del disco duro al finalizar la sesión de navegador (las denominadas cookies de sesión).
          </p>
          <p>
            La mayoría de los navegadores aceptan como estándar a las cookies y, con independencia de las mismas, permiten o impiden en los ajustes de seguridad las cookies temporales o memorizadas. Las cookies se asocian al navegador, no a la persona, por lo que no suelen almacenar información sensible sobre ti como tarjetas de crédito o datos bancarios, fotografías o información personal, etc. Los datos que guardan son de carácter técnico, estadísticos, preferencias personales, personalización de contenidos, etc.
          </p>


          <h2>Aceptación de las cookies: Normativa vigente</h2>
          <p>
            Al acceder a este sitio web, y de acuerdo a la normativa vigente en materia de protección de datos, te informamos del uso de cookies, dándote la opción de aceptarlas expresamente y de acceder a más información a través de esta Política de Cookies.
          </p>
          <p>
            Debes saber que, en el caso de continuar navegando, estarás prestando tu consentimiento para el empleo de estas cookies. Pero, en cualquier momento, podrás cambiar de opinión y bloquear su utilización a través de tu navegador.
          </p>
          <p>
            Para tu total tranquilidad, este sitio web cumple con lo estipulado en la normativa vigente en relación con el uso de las cookies y tus datos personales:
          </p>
          <ul>
            <li>El reglamento LSSI-CE (Ley de la sociedad de la información y del comercio electrónico)</li>
            <li>El RGPD (Reglamento (UE) 2016/679 del Parlamento Europeo y del Consejo de 27 de abril de 2016 relativo a la protección de las personas físicas), que unifica la regulación del tratamiento de los datos personales en los países de la UE.</li>
          </ul>

          <p>
            Esta Política de Cookies podría modificarse en cualquier momento para adaptarse a novedades normativas o cambios en nuestras actividades, siendo vigente la que en cada momento se encuentre publicada en la web.
          </p>

          <h3>Tipos de cookies</h3>
          <p>
            Para poder ofrecerte una mejor experiencia de usuario, obtener datos analíticos, almacenar y recuperar información sobre tus hábitos de navegación o de tu equipo y desarrollar su actividad, este sitio web https://gatherimpulse.com, utiliza cookies propias y de terceros.
          </p>
          <p>
            ¿Qué tipos de cookies utiliza este sitio web?
          </p>
          <ul>
            <li><strong>Cookies técnicas:</strong> Son aquellas que permiten al usuario la navegación a través de una página web, plataforma o aplicación y la utilización de los diferentes servicios que en ella existan como, por ejemplo, controlar el tráfico y la comunicación de datos, identificar la sesión, acceder a partes de acceso restringido, etc.</li>
            <li><strong>Cookies de personalización:</strong> Son aquellas que permiten al usuario acceder al servicio con algunas características predefinidas en función de una serie de criterios en su terminal como el idioma, el tipo de navegador, etc.</li>
            <li><strong>Cookies de análisis:</strong> Son aquellas que permiten realizar el seguimiento y análisis del comportamiento de los usuarios en el sitio web, con el fin de introducir mejoras en la oferta de productos y servicios.</li>
            <li><strong>Cookies publicitarias:</strong> Son aquellas que permiten la gestión eficaz de los espacios publicitarios del sitio web, adecuando el contenido del anuncio al contenido del servicio solicitado o al uso que se hace del sitio web.</li>
            <li><strong>Cookies de publicidad comportamental:</strong> Son aquellas que permiten la gestión de los espacios publicitarios del sitio web de la forma más eficaz posible, almacenando información del comportamiento de los usuarios obtenida a través de la observación continua de sus hábitos de navegación.</li>
            <li><strong>Cookies de terceros:</strong> Este sitio web puede utilizar servicios de terceros que recopilan información con fines estadísticos sobre el uso del sitio por parte del usuario, así como para otros servicios relacionados con la actividad del sitio web y otros servicios de Internet. En particular, se utiliza Google Analytics para estos fines.</li>
          </ul>

          <p>
            Para más información sobre cómo Google utiliza las cookies en Google Analytics y cómo puedes gestionar tus preferencias de privacidad, visita <a href="https://www.google.com/policies/privacy/partners/" target="_blank" rel="noreferrer">Política de Privacidad de Google</a>.
          </p>




          <h2>Gestionar y rechazar el uso de cookies</h2>
          <p>
            En cualquier momento, puedes adaptar la configuración del navegador para gestionar, desestimar el uso de cookies y ser notificado antes de que se descarguen.
          </p>
          <p>
            También puedes adaptar la configuración de forma que el navegador rechace todas las cookies, o únicamente las cookies de terceros. Y también puedes eliminar cualquiera de las cookies que ya se encuentren en tu equipo.
          </p>
          <p>
            Para esto, debes tener en cuenta que tendrás que adaptar por separado la configuración de cada navegador y equipo que utilices ya que, como te hemos comentado anteriormente las cookies se asocian al navegador, no a la persona.
          </p>
          <ul>
            <li><strong>Google Chrome:</strong> <a href="https://support.google.com/chrome/answer/95647?hl=es-419" target="_blank" rel="noreferrer">Cómo gestionar cookies en Chrome</a></li>
            <li><strong>Internet Explorer:</strong> <a href="https://support.microsoft.com/es-es/help/17442/windows-internet-explorer-delete-manage-cookies#ie=ie-10" target="_blank" rel="noreferrer">Cómo gestionar cookies en Internet Explorer</a></li>
            <li><strong>Mozilla Firefox:</strong> <a href="https://support.mozilla.org/es/kb/habilitar-y-deshabilitar-cookies-sitios-web-rastrear-preferencias?redirectlocale=es&redirectslug=habilitar-y-deshabilitar-cookies-que-los-sitios-we" target="_blank" rel="noreferrer">Cómo gestionar cookies en Firefox</a></li>
            <li><strong>Apple Safari:</strong> <a href="https://support.apple.com/es-es/HT201265" target="_blank" rel="noreferrer">Cómo gestionar cookies en Safari</a></li>
          </ul>


          <p>
            Si tienes cualquier duda sobre esta Política de Cookies, puedes contactar con nosotros enviándonos un correo electrónico a <a href="mailto:gather@gatherimpulse.com">gather@gatherimpulse.com</a>.
          </p>

          <p>
            Si has leído hasta aquí todo este tostón y te ha gustado, imagina lo que te gustará tu producto cuando lo terminemos. ¡Vas a flipar!
          </p>

        </LegalNotice>
      </Contenedor>

    </>
  );
};

export default Home;
