import React, { useRef, useEffect, useState } from 'react';
import styled, { keyframes, css } from 'styled-components';
import WORKS from './WORKS.png'; 

const slideIn = keyframes`
  from {
    transform: translateX(100%);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
`;

const ImageWrapper = styled.div.withConfig({
  shouldForwardProp: (prop) => prop !== 'isVisible'
})`
  width: 800px;  // Puedes ajustar el tamaño según tus necesidades
  opacity: ${props => (props.isVisible ? 1 : 0)};
  animation: ${props =>
    props.isVisible &&
    css`
      ${slideIn} 1.5s ease-out
    `};

  transition: opacity 0.5s ease-out;
  margin-left: auto; // Esto mueve el componente hacia la derecha
`;

const Img = styled.img`
  position: relative;
  width: 100%;  // La imagen ocupa todo el contenedor
  opacity: 0.4; // Ajusta la opacidad de la letra aquí

  @media (max-width: 768px) {
    width: 36%;
    margin-left: 90px;
  }
`;

const LetraWorks = () => {
  const [isVisible, setIsVisible] = useState(false);
  const imgRef = useRef();

  useEffect(() => {
    const currentImgRef = imgRef.current;

    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setIsVisible(true);
          if (currentImgRef) {
            observer.unobserve(currentImgRef); // Dejar de observar una vez que la imagen es visible
          }
        }
      },
      { threshold: 0.1 } // El porcentaje de visibilidad necesario para activar la animación
    );

    if (currentImgRef) {
      observer.observe(currentImgRef);
    }

    return () => {
      if (currentImgRef) {
        observer.unobserve(currentImgRef);
      }
    };
  }, []);

  return (
    <ImageWrapper ref={imgRef} isVisible={isVisible}>
      <Img src={WORKS} alt="WORKS" />
    </ImageWrapper>
  );
};

export default LetraWorks;
