import React from 'react';
import styled from 'styled-components';



const InfoContainer = styled.div`


  align-items: flex-start;
  padding: 3vw 9vw;
  position: relative;
  z-index: 10;

  @media (max-width: 768px) {
   
  }
`;




const Linea = styled.div`
top: 160px;
  width: 100%;
  height: 1px;
  background-color: white;
  margin: 0 auto; // Esto centra la línea horizontalmente
  position: relative;

  @media (max-width: 768px) {
    top: 70px;
    width: 100%;
  }
`;



const LineaContainer = () => {
  return (
    <InfoContainer>
 

      <Linea />




    </InfoContainer>
  );
};

export default LineaContainer;
