import React from 'react';
import styled from 'styled-components';

import Fluid3D1 from './Fluid 3D 1.png';
import Fluid3D3 from './Fluid 3D 3.png';



import TituloPrincipal from "../../components/-Hooks/TituloPrincipal/tituloprincipal";
import FormularioHooks from "../../components/-Hooks/FormularioHooks/FormularioHooks";


const Image = styled.img`
  width: 100%;
  height: 100%; 
  position: absolute;
  opacity: 0.7;
  object-fit: cover;
  z-index: 1; 
`;


const Image1 = styled(Image)`

top:-25%;
  @media (max-width: 768px) {
    top:-29%;
  }
`;


const Image3 = styled(Image)`

top:68vh;


  @media (max-width: 768px) {
    top:45%;
  }
`;



const ContentContainer = styled.div`

  flex-direction: column;
  width: 100%;
 z-index: 1; 
`;


const BlackOverlay = styled.div`
 pointer-events: none;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
 
  z-index: 1000;
  animation: fadeOut 0.7s ease-in-out forwards;
 

  @keyframes fadeOut {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
      display: none;
    }
  }
`;



const BackgroundImagesContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 0; /* Muy bajo para asegurarse que esté detrás */
  overflow: hidden;
`;


const Back = styled.div`
background-color: #0a0a0a;
z-index: -1;
min-height: 100vh;
`;


const Footer = styled.footer`
  position: absolute;
  bottom: 0;
  width: 100%;
  text-align: center;
  padding: 20px;
  color: white;
  font-size: 14px;
  z-index: 2; /* Asegura que esté delante de las imágenes de fondo */
`;



const Hooks = () => {
  return (
    <div style={{ position: 'relative' }}>

      <Back> 
      <BlackOverlay />



      <ContentContainer>


        <TituloPrincipal />
         <FormularioHooks />

 
      </ContentContainer>



      <BackgroundImagesContainer>

        <Image1 src={Fluid3D1} alt="Background Image 1" />
        <Image3 src={Fluid3D3} alt="Background Image 3" />
    
      </BackgroundImagesContainer>
      <Footer>
          Copyright &copy; 2024 Powered by Gather Impulse
        </Footer>
</Back>

    </div>

  );
};

export default Hooks;
