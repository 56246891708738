import React from 'react';
import styled from 'styled-components';

// Contenedor principal
const InfoContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 2vw 8vw;
  z-index: 10;
  text-align: center; 
  overflow: hidden;


  @media (max-width: 768px) {
    flex-direction: column; /* Cambiar a columna en móviles */
    padding: 5vw 5vw;
  }
`;

// Contenedor de cada bloque de texto
const TextBlock = styled.div`
  flex: 1;
  padding: 0 2vw;
  margin-bottom: 4vw; /* Espaciado entre bloques */

  @media (max-width: 768px) {
    position: relative; /* Cambiar a relativo en móviles */
    width: 100%;
  }
`;

// Estilos para el título en negrita
const Bold = styled.strong`
  font-family: 'Kanit-SemiBold', sans-serif;
  font-size: 1.2vw;
  line-height: 1;
  color: white;

  @media (max-width: 768px) {
    font-size: 5vw;
    margin-top: 1vw;
  }
`;

// Estilos para el texto de párrafo
const Texto = styled.p`
  font-family: 'Kanit-Light', sans-serif;
  color: white;
  margin: 0;
  font-size: 1vw;
  line-height: 1.2;
  margin-top: 1vw;

  @media (max-width: 768px) {
    margin-top: 3vw;
    font-size: 4vw;
  }
`;

// Componente principal
const TituloPrincipal = () => {
  

  const texts = [
    {
      title: "Velocidad de carga lenta",
      content: "Si tu web tarda más de 3 segundos en cargar, estás perdiendo clientes cada segundo. ¿Sabías que más de la mitad de las personas abandonan un sitio si tarda más de 3 segundos? Eres invisible para ellos."
    },
    {
      title: "Diseño que distrae",
      content: "Una web bonita pero inútil no ayuda. Necesitas un diseño que haga que tus clientes actúen. El diseño de tu web no es una galería de arte; es una herramienta de ventas. Si el diseño no guía a tus clientes a actuar, no sirve."
    },
    {
      title: "No optimizada para móviles",
      content: "Más de la mitad de tu tráfico viene de móviles. Y si tu web no se adapta perfectamente a sus pantallas, olvídate de convertir. Adiós a tus oportunidades. Hoy en día, adaptabilidad móvil no es un lujo, es una necesidad."
    },
    {
      title: "Invisible en Google",
      content: "Si no te encuentran, no existes. El SEO no es opcional, es esencial. Si tu web no está optimizada para SEO desde el día 1, estás jugando con desventaja. ¿Por qué tu competencia aparece en los primeros lugares y tú no? Porque ellos entienden la diferencia."
    }
  ];

  return (
    <InfoContainer>
      {texts.map((text, index) => (
        <TextBlock key={index}>
          <Bold>{text.title}</Bold>
          <Texto>{text.content}</Texto>
        </TextBlock>
      ))}
    </InfoContainer>
  );
};

export default TituloPrincipal;