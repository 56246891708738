import React from 'react';
import styled, { keyframes } from 'styled-components';

const specialWords = {
  'Branding': { fontFamily: "'Kanit-Semibold', sans-serif" },
  'Software': { fontFamily: "'Kanit-Semibold', sans-serif" },
  'Diseño Web': { fontFamily: "'Kanit-Semibold', sans-serif" },
  'SaaS': { exclude: true, color: 'white' }, // Set color to white for 'SaaS'
  'App': { exclude: true, color: 'white' }, // Set color to white for 'App'
};




const InfoContainer = styled.div`

  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  margin-top: 20vh;
  z-index: 10;
  width: 100%;

  @media (max-width: 768px) {
    margin-top: 9vh;
  }
`;

const scrollAnimation = keyframes`
  0% { transform: translateX(0); }
  100% { transform: translateX(-100%); }
`;

const scrollAnimationMobile = keyframes`
  0% { transform: translateX(0); }
  100% { transform: translateX(-200%); }
`;

const TextWrapper = styled.div`
  overflow: hidden;
  width: 100%;
`;

const Texts = styled.div`
  display: flex;
  animation: ${scrollAnimation} 20s linear infinite;

  @media (max-width: 768px) {
    animation: ${scrollAnimationMobile} 15s linear infinite;
  }
`;

const TextItem = styled.div`
  font-family: 'Kanit-ExtraLight', sans-serif;
  font-size: 5em;
  margin-right: 8vw;
  color: transparent; /* Cambiar el color a transparente */
  position: relative; /* Importante para el efecto de relieve */

  ${({ word }) => !specialWords[word]?.exclude && `
    -webkit-text-stroke: 0.5px rgb(255, 255, 255);
    text-stroke: 0.5px rgb(255, 255, 255);
    background-color: #0000000; /* Ajustar el color de fondo para visibilidad */
    -webkit-background-clip: text; /* Clip para aplicar el color de fondo */
    background-clip: text;
  `}

  ${({ word }) => specialWords[word] && specialWords[word].fontFamily && `
    font-family: ${specialWords[word].fontFamily};
  `}

  ${({ word }) => specialWords[word] && specialWords[word].color && `
    color: ${specialWords[word].color}; /* Establecer color desde specialWords */
  `}
`;

const words = ['Branding', 'App', 'Software', 'SaaS', 'Diseño Web'];

const TextoInfinito = () => {
  const clonedWords = [...words, ...words];

  return (
    <InfoContainer>
      <TextWrapper>
        <Texts>
          {clonedWords.map((word, index) => (
            <TextItem key={index} word={word}>
              {word.includes(' ') ? <span style={{ whiteSpace: 'nowrap' }}>{word}</span> : word}
            </TextItem>
          ))}
        </Texts>
      </TextWrapper>
    </InfoContainer>
  );
};

export default TextoInfinito;
