import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import emailjs from 'emailjs-com';

const ContainerFormulario = styled.div`
  z-index: 30;
  width: 81%;

  margin: auto; /* Centrar horizontalmente */
  display: flex;
  flex-direction: column;

  margin-top: 0px;

  @media (max-width: 768px) {

    width: 90%;
    margin-top: 10px;
    
  }

`;



const Row = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: center;

  @media (max-width: 808px) {
    flex-direction: column;
    margin: 0 auto; /* Centrar horizontalmente */
    width: 100%;
 
  }
`;


const Column = styled.div`
  flex: 1;
  min-width: 300px;
  margin: 10px;

 
`;

const Input = styled.input`
  backdrop-filter: blur(8px); 
  color: white;
  background-color: rgba(255, 255, 255, 0.13);
  font-family: 'Kanit-Light', sans-serif;
  width: calc(100% - 0px);
  padding: 15px;
  margin: 10px 0;
  border: 1px solid #ccc;
  border-radius: 20px;
  font-size: 16px;
`;

const TextArea = styled.textarea`
  backdrop-filter: blur(8px); 
  color: white;
  background-color: rgba(255, 255, 255, 0.13);
  font-family: 'Kanit-Light', sans-serif;
  width: calc(100% - 0px);
  padding: 15px;
  margin: 10px 0;
  border: 1px solid #ccc;
  border-radius: 20px;
  font-size: 16px;
  resize: vertical;
  height: 209px;
`;

const RowBotones = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 100%;

  @media (max-width: 768px) {
    margin-top: 10px;
    justify-content: space-around;
    flex-wrap: wrap;
    
  }
`;

const Button = styled.button`
  color: white;
  background-color: rgba(255, 255, 255, 0.13);
  font-family: 'Kanit-Light', sans-serif;
  padding: 10px;
  width: 170px;
  margin: 10px;
  border: 1px solid #ccc;
  border-radius: 20px;
  font-size: 20px;
  cursor: pointer;

  ${(props) =>
    props.selected &&
    css`
      background-color: #f6e82353;
    `}

  @media (max-width: 768px) {
    width: 150px;
    font-size: 15px;
    margin: 7px;
  }
`;

const TituloBotones = styled.div`
  color: white;
  font-family: 'Kanit-ExtraLight', sans-serif;
  margin: 5px;

  @media (max-width: 768px) {
    font-size: 3vw;
    margin: 10px;
  }
`;

const EnviarButton = styled.button`
  font-size: 25px;
 
  background-color: transparent; /* Cambiado a fondo transparente */
  color: white;
  font-family: 'Kanit-SemiBold', sans-serif;
  width: 100px;
  margin-left: 10px;
  border: none; /* Elimina el borde */
  @media (max-width: 508px) {
  
  
}
`;


const SuccessMensaje = styled.div`
  color: #56af44;
  font-size: 14px;
  margin-top: 5px;
  margin-left: 10px;
`;


const ErrorMensaje = styled.div`
  margin-left: 10px;
  color: #ff000094;
  font-size: 14px;
  margin-top: 5px;
`;

const FormularioContacto = () => {
  const [selectedButtons, setSelectedButtons] = useState([false, false, false, false]);
  const [nombre, setNombre] = useState('');
  const [email, setEmail] = useState('');
  const [telefono, setTelefono] = useState('');
  const [consulta, setConsulta] = useState('');
  const [error, setError] = useState('');
  const [successMessage, setSuccessMessage] = useState(''); 

  const toggleButton = (index) => {
    const newSelectedButtons = [...selectedButtons];
    newSelectedButtons[index] = !newSelectedButtons[index];
    setSelectedButtons(newSelectedButtons);
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    // Validar campos
    if (!nombre || !email || selectedButtons.every(btn => !btn)) {
      setError('Por favor completa todos los campos requeridos.');
      return;
    }

    let nuevaConsulta = '';
    selectedButtons.forEach((selected, idx) => {
      if (selected) {
        nuevaConsulta += (nuevaConsulta.length > 0 ? ' ' : '') + ['WEBSITE', 'APP', 'SOFTWARE', 'SAAS'][idx];
      }
    });

    // Limpiar errores si todo está bien
    setError('');

    // Preparar datos para enviar con EmailJS
    const templateParams = {
      user_name: nombre,
      correo: email,
      telefono,
      mensaje: consulta,
      project_interest: nuevaConsulta
    };

    emailjs.send('service_lnol3oz', 'template_n8vuftb', templateParams, 'gSrabvbb9CQdpKCkL')
    .then((response) => {
      console.log('SUCCESS!', response.status, response.text);
      setSuccessMessage('Mensaje enviado con éxito'); // Mostrar mensaje de éxito
    }, (error) => {
      console.log('FAILED...', error);
    });

  

    setNombre('');
    setEmail('');
    setTelefono('');
    setConsulta('');
    setSelectedButtons([false, false, false, false]);
  };

  return (
    <ContainerFormulario>
      <form onSubmit={handleSubmit}>
        <Row>
          <Column>
            <Input
              type="text"
              placeholder="Nombre"
              value={nombre}
              onChange={(e) => setNombre(e.target.value)}
            />
            <Input
              type="email"
              placeholder="Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <Input
              type="tel"
              placeholder="Teléfono"
              value={telefono}
              onChange={(e) => setTelefono(e.target.value)}
            />
          </Column>
          <Column>
            <TextArea
              placeholder="Consulta"
              value={consulta}
              onChange={(e) => setConsulta(e.target.value)}
            />
          </Column>
        </Row>
        <TituloBotones>ESTOY INTERESADO EN UN PROYECTO DE:</TituloBotones>
        <RowBotones>
          <Button type="button" selected={selectedButtons[0]} onClick={() => toggleButton(0)}>
            WEBSITE
          </Button>
          <Button type="button" selected={selectedButtons[1]} onClick={() => toggleButton(1)}>
            APP
          </Button>
          <Button type="button" selected={selectedButtons[2]} onClick={() => toggleButton(2)}>
            SOFTWARE
          </Button>
          <Button type="button" selected={selectedButtons[3]} onClick={() => toggleButton(3)}>
            SAAS
          </Button>
          </RowBotones>
        {error && <ErrorMensaje>{error}</ErrorMensaje>}
        {successMessage && <SuccessMensaje>{successMessage}</SuccessMensaje>}
        <Row>
          <Column>
            <EnviarButton type="submit">ENVIAR</EnviarButton>
          </Column>
        </Row>
      </form>
    </ContainerFormulario>
  );
};

export default FormularioContacto;
