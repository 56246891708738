import React, { useRef } from 'react';
import styled from 'styled-components';

import Fluid3D1 from './Fluid 3D 1.png';
import Fluid3D3 from './Fluid 3D 3.png';
import Fluid3D4 from './Fluid 3D 4.png';



import TituloPrincipal from "../../components/-Call/TituloPrincipal/tituloprincipal";
import Boton from "../../components/-Call/BotonAgendaTuLlamada/Boton";
import TituloAmarillo from "../../components/-Call/TituloAmarillo/TituloAmarillo";
import Parrafo from "../../components/-Call/Parrafo/Parrafo";
import FourTextos from "../../components/-Call/4textos/4textos";
import BotonTeSuena from "../../components/-Call/BotonTeSuenaFamiliar/BotonTeSuenaFamiliar";
import NoUsamosPlantillas from "../../components/-Call/NoUsamosPlantillas/NoUsamosPlantillas";
import TextosDiferencia from "../../components/-Call/4TextosDiferencia/4TextosDiferencia";
import BotonPuedesAgendarHoy from "../../components/-Call/BotonPuedesAgendarHoy/BotonPuedesAgendarHoy";
import DejaDePerderOportunidades from "../../components/-Call/DejaDePerderOportunidades/DejaDePerderOportunidades";
import BotonesTipos from "../../components/-Call/BotonesTipos/BotonesTipos";
import ImaginaPoderAutomatizar from "../../components/-Call/ImaginaPoderAutomatizar/ImaginaPoderAutomatizar";
import Calendly from "../../components/-Call/Calendly/Calendly";



const Image = styled.img`
  width: 100%;
  
  position: absolute;
  opacity: 0.7;
  object-fit: cover;
  z-index: 0; 
`;


const Image1 = styled(Image)`
 width: 100%;
top:-17%;

  @media (max-width: 768px) {
    top:-1%;
  }
`;


const Image3 = styled(Image)`
top:139vh;


  @media (max-width: 768px) {
    top:61%;
  }
`;


const Image4 = styled(Image)`
top:39vh;
width: 70%;
left: 50%;
  @media (max-width: 768px) {
    top:35%;
    width: 100%;
left: 5%;
  }
`;




const ContentContainer = styled.div`

  flex-direction: column;
  width: 100%;
 z-index: 1; 
 position: relative; 
`;


const BlackOverlay = styled.div`
 pointer-events: none;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
 
  z-index: 1000;
  animation: fadeOut 0.7s ease-in-out forwards;
 

  @keyframes fadeOut {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
      display: none;
    }
  }
`;



const BackgroundImagesContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 0; 
  overflow: hidden;
 
`;


const Back = styled.div`
background-color: #0a0a0a;
z-index: -1;
min-height: 100vh;
`;



const Footer = styled.footer`
  position: absolute;
  bottom: 0;
  width: 100%;
  text-align: center;
  padding: 20px;
  color: white;
  font-size: 14px;
  z-index: 2; /* Asegura que esté delante de las imágenes de fondo */
`;


const StyledLink = styled.a`
  color: #D9C01E; /* Color del enlace */
  text-decoration: none;

  &:hover {
    text-decoration: underline; /* Subraya el enlace al pasar el cursor */
  }
`;


const Hooks = () => {
  const calendlyRef = useRef(null);

  const scrollToCalendly = () => {
    if (calendlyRef.current) {
      calendlyRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <div style={{ position: 'relative' }}>

      <Back> 
      <BlackOverlay />



      <ContentContainer>


        <TituloPrincipal />
        <Boton scrollToCalendly={scrollToCalendly} />
        <TituloAmarillo />
        <Parrafo />
        <FourTextos />
        <BotonTeSuena scrollToCalendly={scrollToCalendly} />
        <NoUsamosPlantillas />
        <TextosDiferencia />
        <BotonPuedesAgendarHoy scrollToCalendly={scrollToCalendly} />
        <DejaDePerderOportunidades />
        <BotonesTipos />
        <ImaginaPoderAutomatizar />
        <div ref={calendlyRef}>
            <Calendly />
          </div>
        
 
      </ContentContainer>



      <BackgroundImagesContainer>

        <Image1 src={Fluid3D1} alt="Background Image 1" />
        <Image3 src={Fluid3D3} alt="Background Image 3" />
        <Image4 src={Fluid3D4} alt="Background Image 4" />
    
      </BackgroundImagesContainer>



      <Footer>
          Copyright &copy; 2024 Powered by{' '}
          <StyledLink href="https://gatherimpulse.com/" target="_blank" rel="noopener noreferrer">
            Gather Impulse
          </StyledLink>
        </Footer>
</Back>

    </div>

  );
};

export default Hooks;
