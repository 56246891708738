import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link'; 

import FaLogo from './Fa.png';
import LiLogo from './Li.png';
import WaLogo from './Wa.png';


const InfoContainer = styled.div`
z-index: 400!Important;
z-index: 3;
  padding: 2vw 10vw;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  color: white;

  @media (max-width: 1268px) {
   
    flex-direction: column;
    text-align: center;
    align-items: center;
    
  }
`;

const LeftSection = styled.div`
  z-index: 400;
  font-family: 'Kanit-Semi-Bold', sans-serif;
  flex: 1;
  text-align: left;
  font-size: 1vw;
  margin-bottom: 20px;

  @media (max-width: 1268px) {
    text-align: center;
    margin-bottom: 20px;
  
    font-size: 4vw;
  }

`;

const RightSection = styled.div`
  z-index: 400;
  font-family: 'Kanit-ExtraLight', sans-serif;
  flex: 2; /* Incrementa el valor de flex */
  display: flex;
  justify-content: space-between;
  text-align: left;
  font-size: 1vw;

  @media (max-width: 1268px) {
    flex-direction: row;
    gap: 27px;
    
    flex-wrap: wrap;
    justify-content: center;
    font-size: 2vw;
  }
`;

const Column = styled.div`
  font-family: 'Kanit-Bold', sans-serif;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 35px; 
  gap: 15px;



  justify-content: space-between;


 

  @media (max-width: 1268px) {
  
    margin: 0px;


  }
`;

const CenteredDiv = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 20%;
  cursor: pointer; 
  &:hover {
    color: #F6E823;
  }

  @media (max-width: 768px) {
    margin-left: 20px;
    margin-right: 20px;

  }
`;


const Logo = styled.img`

  height: 30px;


  @media (max-width: 1268px) {
    height: 20px; /* Tamaño para tablets o pantallas medianas */
  }

  @media (max-width: 768px) {
    height: 20px; /* Tamaño para móviles */

  }
`;

const NavLink = styled(Link)`
  text-decoration: none;
  color: #ffffff;
  font-size: 18px;
  cursor: pointer; /* Asegura que el cursor sea una mano al pasar sobre el enlace */

 
  &:hover {
    color: #F6E823;
  }
  @media (max-width: 768px) {
   
    font-size: 15px;
  }
`;

const HashLinkStyled = styled(HashLink)`
  text-decoration: none;
  color: #ffffff;
  font-size: 18px;


  cursor: pointer; /* Asegura que el cursor sea una mano al pasar sobre el enlace */
  &:hover {
    color: #f6e823;
  }

  @media (max-width: 768px) {
    font-size: 15px;
  }
`;



const Footer = () => {
  return (
    <InfoContainer>

      <LeftSection>
        Branding - Diseño Web - UX/UI <br></br>
          Software - Saas - App
      </LeftSection>

      <RightSection>
        <Column>
        <HashLinkStyled smooth to="/legales#cookie">COOKIES</HashLinkStyled>
          <HashLinkStyled smooth to="/legales#privacidad">PRIVACIDAD</HashLinkStyled>
           <NavLink to="/legales">LEGALES</NavLink>
        </Column>
        <Column>
        <NavLink to="/home">HOME</NavLink>
          <HashLinkStyled smooth to="/home#about-us">ABOUT US</HashLinkStyled>
          <NavLink to="/contacto">CONTACTO</NavLink>
        </Column>
        <Column>
  <CenteredDiv>
    <a href="https://www.linkedin.com/company/gather-impulse/posts/?feedView=all" target="_blank" rel="noopener noreferrer">
      <Logo src={LiLogo} alt="LinkedIn Logo" />
    </a>
  </CenteredDiv>
  <CenteredDiv>
    <a href="https://www.facebook.com/gatherimpulse" target="_blank" rel="noopener noreferrer">
      <Logo src={FaLogo} alt="Facebook Logo" />
    </a>
  </CenteredDiv>
  <CenteredDiv>
    <a href="https://wa.me/655416503" target="_blank" rel="noopener noreferrer">
      <Logo src={WaLogo} alt="WhatsApp Logo" />
    </a>
  </CenteredDiv>
</Column>

      </RightSection>

    </InfoContainer>
  );
};

export default Footer;
