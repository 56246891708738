// Header.js
import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link'; 


const HeaderContainer = styled.header`
  position: fixed;
  top: 0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px 20px;
  background-color: rgba(255, 255, 255, 0); /* Fondo blanco semitransparente */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(10px); /* Añade un efecto de desenfoque al fondo */
  z-index: 1000; /* Asegura que el header esté por encima de otros elementos */
`;

const Logo = styled.img`
  height: 70px;

  @media (max-width: 768px) {
    height: 50px;
    margin-left: 10px;
  }
`;

const Nav = styled.nav`
  display: flex;
  gap: 60px; /* Reducir el espacio entre los enlaces */
  font-family: 'Kanit-ExtraLight';
  padding-right: 90px; /* Agregar padding a la derecha */


  @media (max-width: 768px) {
    display: none; /* Ocultar la navegación en pantallas pequeñas */
  }
`;

const NavLink = styled(Link)`
  text-decoration: none;
  color: #ffffff;
  font-size: 18px;
  cursor: pointer; /* Asegura que el cursor sea una mano al pasar sobre el enlace */
  &:hover {
    color: #F6E823;
  }
`;

const HashLinkStyled = styled(HashLink)`
  text-decoration: none;
  color: #ffffff;
  font-size: 18px;
  cursor: pointer; /* Asegura que el cursor sea una mano al pasar sobre el enlace */
  &:hover {
    color: #f6e823;
  }
`;



const Header = () => {
  return (
  
 
  
  
  <HeaderContainer>
         <Link to="/">
        <Logo src="/Logo letra.png" alt="Logo" />
      </Link>
      <Nav>
        <HashLinkStyled smooth to="/home#work">SOLUCIONES</HashLinkStyled>

      <HashLinkStyled smooth to="/home#about-us">ABOUT US</HashLinkStyled>

        <NavLink to="/contacto">CONTACTO</NavLink>
        
       
      </Nav>
    </HeaderContainer>
  );
};

export default Header;
