import React from 'react';
import styled from 'styled-components';

const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-left: 35vw;
  padding-right: 35vw;
  padding-bottom: 2vw;
  padding-top: 0vw;
  position: relative;
  z-index: 10;

  text-align: center; /* Alinea el texto al centro */

  @media (max-width: 768px) {
    
    padding-left: 15vw;
    padding-right: 15vw;
    padding-top: 0vw;
  }
`;


const Subtitulo = styled.p`
   /* font-family: 'Kanit-SemiBold', sans-serif; */
   font-family: 'Kanit-Light', sans-serif;
  color: white;
  margin: 0vw 0 0vw 0;
  padding: 0;
  width: 100%;
  font-size: 1.2vw;
  line-height: 1.2;

  @media (max-width: 768px) {
    margin: 3vw 0 0vw 0;
    font-size: 4vw;
    line-height: 1.2;
  }
`;



const Bold = styled.strong`
    font-family: 'Kanit-SemiBold', sans-serif;
    font-size: 1.2vw;
    line-height: 1;

    @media (max-width: 768px) {
    font-size: 4.2vw;
   
  }
`;







const TituloPrincipal = () => {
  return (
    <InfoContainer>

  
      <Subtitulo>


Si algo de lo que viene a 
continuación te suena familiar, es probable 
que estés <Bold>cometiendo errores</Bold> clave  <br></br><br></br>

Y lo peor es que cada 
uno de esos errores <Bold>te está costando dinero </Bold>
      </Subtitulo>





    </InfoContainer>
  );
};

export default TituloPrincipal;
