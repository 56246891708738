import React from 'react';
import styled from 'styled-components';

const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-left: 20vw;
  padding-right: 20vw;
  padding-bottom: 2vw;
  padding-top: 4vw;
  position: relative;
  z-index: 10;

  text-align: center; /* Alinea el texto al centro */

  @media (max-width: 768px) {
    
    padding-left: 8vw;
    padding-right: 8vw;
    padding-top: 15vw;
  }
`;

const Titulo = styled.p`
  font-family: 'Kanit-SemiBold', sans-serif;
  color: white;
  margin: 0 0 3vw 0;
  padding: 0;
  width: 100%;
  font-size: 4vw; /* Aumenté el tamaño de la fuente */
  line-height: 1.2;
  letter-spacing: 0.35em; /* Añadí espacio entre las letras */
  z-index: 55000 !important;

  @media (max-width: 768px) {
    font-size: 8vw; /* También aumenté el tamaño para pantallas pequeñas */
    line-height: 1.2;
    letter-spacing: 0.35em; /* Añadí espacio entre las letras */
  }
`;


const Subtitulo = styled.p`
   font-family: 'Kanit-SemiBold', sans-serif;
  color: white;
  margin: 0vw 0 3vw 0;
  padding: 0;
  width: 100%;
  font-size: 2vw;
  line-height: 1.2;

  @media (max-width: 768px) {
    margin: 6vw 0 6vw 0;
    font-size: 4vw;
    line-height: 1.2;
  }
`;

const Parrafo = styled.p`
  font-family: 'Kanit-Light', sans-serif;
  color: white;
  margin: 0vw 0 1vw 0;
  padding: 0;
  font-size: 1.6vw;
  line-height: 1.2;

  @media (max-width: 768px) {
    margin: 3vw 0 3vw 0;
    font-size: 4vw;
    line-height: 1.2;
  }
`;

const Bold = styled.strong`
    font-family: 'Kanit-SemiBold', sans-serif;
    font-size: 2.1vw;
    line-height: 1;

    @media (max-width: 768px) {
    font-size: 5vw;
   
  }
`;


const ParrafoAmarillo = styled.p`
  font-family: 'Kanit-Light', sans-serif;
  color: white;
  margin: 3vw 0 4vw 0;
  padding: 0;
  font-size: 2vw;
  line-height: 1.0;

  color: #D9C01E; 

  @media (max-width: 768px) {
    margin: 6vw 0 6vw 0;
    font-size: 5vw;
    line-height: 1.1;
  }
`;






const TituloPrincipal = () => {
  return (
    <InfoContainer>

      <Titulo>
        250 HOOKS PARA <br />
        VENDER EN<br />
        EL SECTOR IT
      </Titulo>

      <Subtitulo>
        ¿Cómo puedes cambiar tu negocio con las <br />
        palabras adecuadas?
      </Subtitulo>

      <Parrafo>
        Si estás buscando formas de acelerar tu crecimiento, atraer más 
        clientes y cerrar más ventas, no puedes quedarte atrás.
      </Parrafo>

      <Parrafo>
    En <Bold>Gather Impulse</Bold>, hemos reunido 250 frases irresistibles que están 
    diseñadas para generar impacto, captar la atención y provocar acción inmediata en tu cliente potencial.
  </Parrafo>

      <ParrafoAmarillo>
        Estas no son frases comunes, sino hooks que funcionan para <br></br> 
        <Bold>appweb, para SaaS, app, o sector IT</Bold> en general... 
      </ParrafoAmarillo>

      <Parrafo>
      Y lo mejor de todo, es que son <Bold>totalmente gratis!</Bold> Por ahora. No te quedes fuera mientras otros ya están sacando ventaja. Descarga este documento 
        exclusivo hoy mismo y empieza a desmarcarte del resto.
      </Parrafo>

      <Parrafo>
      <Bold> El tiempo corre.</Bold> 
      </Parrafo>

    </InfoContainer>
  );
};

export default TituloPrincipal;
