import React from 'react';
import styled from 'styled-components';


const Container = styled.div`
 display: flex;
  justify-content: center;  
  align-items: center;      
`;


const Button = styled.button`
  background-color: #42b82711;
  color: white;
  border: none;
  border-radius: 46px;
  cursor: default; /* Cambiado de pointer a default */
  font-family: 'Kanit-SemiBold', sans-serif;
  border: 1px solid #ffffffcf;
  width: auto;
  font-size: 1.4vw;
  padding-left: 2vh;
  padding-right: 2vh;
  margin: 1vh;

  @media (max-width: 768px) {
  

    padding-left: 0.1vh;
    padding-right: 0.1vh;
    width: auto;
    padding: 0.5vh;
    margin-left:4px;
    margin-right:4px;
    font-size: 2.9vw;
    margin-bottom: 20px;
    margin-top: 20px;
   
    
  }
`;



const Boton = () => {


  return (
    <Container>

  
           

          <Button >WEBSITE </Button>   <Button >APP</Button>   <Button >SOFTWARE </Button>   <Button >SAAS </Button><Button >CIBERSEGURIDAD </Button>

     
    </Container>
  );
};

export default Boton;
