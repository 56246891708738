import { BrowserRouter, Route, Routes, useLocation } from 'react-router-dom';
import { useEffect } from 'react';

import Home from '../../views/Home/home.jsx'
import Welcome from '../../views/Welcome/welcome.jsx'
import Contacto from '../../views/Contacto/contacto.jsx'
import NotFoundPage from '../../views/NotFoundPage/notfoundpage.jsx';
import Legales from '../../views/Legales/legales.jsx';

import Hooks from '../../views/Hooks/Hooks.jsx';
import Call from '../../views/Call/Call.jsx';


function ScrollToTop() {
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  return null;
}

const Router = () => {



  return (
    <BrowserRouter>
      <ScrollToTop />
      <Routes>



      <Route path="/" element={<Welcome />} />

        <Route path="home" element={<Home />} />

        <Route path="contacto" element={<Contacto />} />

        <Route path="legales" element={<Legales />} />



        <Route path="hooks" element={<Hooks />} />

        <Route path="call" element={<Call />} />


        <Route path="*" element={<NotFoundPage />} />

      </Routes>
    </BrowserRouter>
  );
};

export default Router;