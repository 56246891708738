import React, { useRef, useEffect, useState } from 'react';
import styled, { keyframes, css } from 'styled-components';
import DESIGN from './DESIGN THINKING.png'; // Asegúrate de tener la ruta correcta de la imagen

// Definimos una animación con keyframes
const slideIn = keyframes`
  from {
    transform: translateY(100%);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
`;

const ImageWrapper = styled.div`
  z-index: 4;
  width: 45%;

  margin-left: 150px;
  opacity: ${props => (props.isVisible ? 1 : 0)};
  animation: ${props =>
    props.isVisible &&
    css`
      ${slideIn} 1.7s ease-out
    `};
  transition: opacity 0.5s ease-out;
  text-align: left;

  @media (max-width: 768px) {
    margin-left: 30px;
 
    width: 240%;
  }
`;

const Img = styled.img`
  z-index: 4;
  position: relative;
  width: 90%;
  opacity: 1;

  @media (max-width: 768px) {
    width: 36%;
  }
`;

const LetraWorks = () => {
  const [isVisible, setIsVisible] = useState(false);
  const imgRef = useRef();

  useEffect(() => {
    const currentImgRef = imgRef.current;

    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setIsVisible(true);
          if (currentImgRef) {
            observer.unobserve(currentImgRef); // Dejar de observar una vez que la imagen es visible
          }
        }
      },
      { threshold: 0.1 }
    );

    if (currentImgRef) {
      observer.observe(currentImgRef);
    }

    return () => {
      if (currentImgRef) {
        observer.unobserve(currentImgRef);
      }
    };
  }, []);

  return (
    <ImageWrapper ref={imgRef} isVisible={isVisible}>
      <Img src={DESIGN} alt="DESIGN" />
    </ImageWrapper>
  );
};

export default LetraWorks;
