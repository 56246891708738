import React from 'react';
import styled from 'styled-components';
import videoWebM from './VideoFluid.webm';
import videoMP4 from './VideoFluid.mp4';

const InfoContainer = styled.div`
  align-items: flex-start;
  padding: 3vw 9vw;
  position: relative;
  z-index: 10;

  @media (max-width: 768px) {
    padding: 2vw 4vw;
  }
`;

const VideoBackground = styled.video`
  position: fixed;
  top: 45%;
  left: 50%;
  width: 65%;
  height: auto;
  transform: translate(-50%, -50%);
  object-fit: cover;
  z-index: -1; 
  opacity: 1; 

  @media (max-width: 768px) {
    width: 160vw;
    height: auto;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    object-fit: contain;
  }
`;

const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;

const VideoFluid = () => {
  return (
    <>
      <VideoBackground autoPlay loop muted playsInline>
        {isIOS ? (
          <source src={videoMP4} type="video/mp4" />
        ) : (
          <source src={videoWebM} type="video/webm" />
        )}
        Tu navegador no soporta el formato de video.
      </VideoBackground>
      <InfoContainer>
      </InfoContainer>
    </>
  );
};

export default VideoFluid;
