import React from 'react';
import styled from 'styled-components';



const Container = styled.div`

  justify-content: center;  /* Centra horizontalmente */
  align-items: center;      /* Centra verticalmente */
  text-align: center;   
  
`;





const ParrafoBlanco = styled.p`
  font-family: 'Kanit-Light', sans-serif;
  color: white;
  margin: 3vw 27vw 3vw 27vw;
  padding: 0;
  font-size: 2vw;
  line-height: 1.0;

  color: #ffffff; 

  @media (max-width: 768px) {
    margin: 3vw 14vw 6vw 14vw;
    font-size: 5vw;
    line-height: 1.1;
  }
`;


const ParrafoAmarillo = styled.p`
  font-family: 'Kanit-SemiBold', sans-serif;
  color: white;
  margin: 3vw 27vw 3vw 27vw;
  padding: 0;
  font-size: 2vw;
  line-height: 1.0;

  color: #D9C01E; 

  @media (max-width: 768px) {
    margin: 3vw 14vw 1vw 14vw;
    font-size: 6vw;
    line-height: 1.1;
  }
`;





const TituloPrincipal = () => {
  return (


    <Container>
   

   
      <ParrafoBlanco>
      No usamos plantillas genéricas. No solo 
creamos webs estéticas, creamos 
productos para vender
        
      </ParrafoBlanco>

     
      <ParrafoAmarillo>
      Mira la diferencia
        
      </ParrafoAmarillo>

   
    
    </Container>
  );
};

export default TituloPrincipal;
