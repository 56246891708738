import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

// Estilos para la tarjeta
const Card = styled.div`
  backdrop-filter: blur(8px);
  z-index: 100;
  background-color: rgba(5, 71, 41, 0.2);
  padding: 30px;
  padding-top: 80px;
  max-width: 450px;
  height: 650px;
  text-align: center;
  border-radius: 46px;
  border: 1px solid #989218cf;
  overflow: hidden;

  @media (max-width: 768px) {
    padding: 20px;
    padding-top: 30px;
    max-width: 90%;
    height: auto;
    border-radius: 30px;
  }
`;

const Title = styled.h2`
  font-family: 'Kanit-SemiBold', sans-serif;
  color: white;
  font-size: 40px;
  margin: -10px;

  .highlight {
    color: #989218;
    font-size: 40px;
  }

  @media (max-width: 768px) {
    font-size: 30px;

    .highlight {
      font-size: 35px;
    }
  }
`;

const Text = styled.p`
  font-family: 'Kanit-ExtraLight', sans-serif;
  color: white;
  font-size: 40px;
  margin: -10px;

  @media (max-width: 768px) {
    font-size: 30px;
    margin: 0;
  }
`;

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 33200;
`;

const CloseButton = styled.button`
  position: absolute;
  top: 18px;
  right: 10px;
  background: #ff000084;
  color: white;
  border: none;
  border-radius: 5px;
  margin: 5px;
  padding: 10px auto;
  cursor: pointer;

  @media (max-width: 768px) {
    padding: 8px auto;
  }
`;

const Up = styled.div`
  margin-bottom: 30px;
  @media (max-width: 768px) {}
`;

const Input = styled.input`
  margin: 10px 0;
  padding: 10px;
  width: 80%;
  border: 1px solid #ffffffcf;
  border-radius: 46px;
  background-color: rgba(0, 0, 0, 0.089);
  color: white;
  font-family: 'Kanit-ExtraLight', sans-serif;
  font-size: 1.2em;
  outline: none;

  ::placeholder {
    color: rgba(255, 255, 255, 1);
    font-size: 1.4em;
  }

  &:focus {
    border-color: #989218cf;
  }

  @media (max-width: 768px) {
    padding: 8px;
    font-size: 4vw;
  }
`;

const CheckboxContainer = styled.label`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
  font-family: 'Kanit-ExtraLight', sans-serif;
  color: white;
  text-align: center;

  input[type="checkbox"] {
    margin-right: 10px;
    transform: scale(1.4);
    cursor: pointer;
  }
`;

const PrivacyLink = styled.a`
  color: #989218;
  text-decoration: none;
  margin-left: 5px;

  &:hover {
    text-decoration: underline;
  }
`;

const Button = styled.button`
  background-color: #989218cf;
  color: white;
  padding: 0px 0px;
  border: none;
  border-radius: 46px;
  cursor: pointer;
  font-family: 'Kanit-ExtraLight', sans-serif;
  border: 1px solid #ffffffcf;
  width: 80%;
  font-size: 25px;
  margin-top: 40px;

  @media (max-width: 768px) {
    padding: 4px;
    font-size: 5vw;
    margin-bottom: 20px;
    margin-top: 20px;
  }
`;

// Añadimos un nuevo estilo para el mensaje de error
const ErrorMessage = styled.p`
  color: red;
  font-family: 'Kanit-ExtraLight', sans-serif;
  font-size: 15px;
  margin-top: 10px;
`;

const PopUp = () => {
  const [isVisible, setIsVisible] = useState(false);
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [acceptPrivacy, setAcceptPrivacy] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [loading, setLoading] = useState(false); // Estado de carga

  useEffect(() => {
    const hasShownPopup = sessionStorage.getItem('hasShownPopup');

    if (!hasShownPopup) {
      const timer = setTimeout(() => {
        setIsVisible(true);
        sessionStorage.setItem('hasShownPopup', 'true');
      }, 10000);

      return () => clearTimeout(timer);
    }
  }, []);

  const closePopUp = () => {
    setIsVisible(false);
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();
  
    // Validación de campos
    if (!name || !email || !validateEmail(email) || !acceptPrivacy) {
      setErrorMessage('Datos incorrectos. Por favor, revisa los campos');
      return;
    }
  
    setErrorMessage('');
    setLoading(true);
  
    try {
      const response = await fetch('https://script.google.com/macros/s/AKfycbxGP0zF262L8rYnyBUOIIoC0mCHVJ8f2jwttJXJN7U6xEPXGIP_tWDk1xYGsz4jmpsP_g/exec', {
        method: 'POST',
        body: JSON.stringify({ name, email }), // Enviar datos directamente
      });
  
      if (response.ok) {
        const link = document.createElement('a');
        link.href = '/250%20Hooks%20para%20vender%20más%20en%20el%20sector%20IT_FRUSTRADO%20POR%20LA%20TECNOLOGÍA_Gather%20Impulse%20DEV.pdf';
        link.download = '250 Hooks para vender más en el sector IT.pdf';
        link.click();
        closePopUp();
      } else {
        const errorData = await response.json(); 
        setErrorMessage(`Hubo un problema al enviar los datos: ${errorData.message || 'error desconocido'}`);
      }
    } catch (error) {
      setErrorMessage(`Error al enviar los datos: ${error.message}`);
    } finally {
      setLoading(false);
    }
  };
  

  const validateEmail = (email) => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);

  return (
    <div>
      {isVisible && (
        <Overlay>
          <Card>
            <CloseButton onClick={closePopUp}>X</CloseButton>
            <Up>
              <Title>
                Descarga <span className="highlight">GRATIS</span> <br /> 250 Hooks:
              </Title>
              <Text>Optimiza tu</Text>
              <Title>negocio</Title>
              <Text>en el sector IT</Text>
            </Up>

            <form onSubmit={handleFormSubmit}>
              <Input
                type="text"
                placeholder="Nombre"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
              <Input
                type="email"
                placeholder="Email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />

              <CheckboxContainer>
                <input
                  type="checkbox"
                  checked={acceptPrivacy}
                  onChange={(e) => setAcceptPrivacy(e.target.checked)}
                />
                Acepto las
                <PrivacyLink href="/legales" target="_blank">
                  políticas de privacidad
                </PrivacyLink>
              </CheckboxContainer>

              <Button type="submit" disabled={loading}>DESCARGAR</Button>

              {loading && <p>Cargando...</p>} {/* Mensaje de carga */}
              {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
            </form>
          </Card>
        </Overlay>
      )}
    </div>
  );
};

export default PopUp;
