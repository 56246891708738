import React from 'react';
import styled from 'styled-components';

const InfoContainer = styled.div`
  padding: 1vw 4vw;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  z-index: 100;
  @media (max-width: 1268px) {
    flex-direction: column;
    text-align: center;
    padding: 1vw 2vw;
  }
`;

const TextContainer = styled.div`
  z-index: 3;
  width: 100%;
  font-size: 1.1vw;
  font-family: 'Kanit-ExtraLight', sans-serif;
  text-align: center;


  @media (max-width: 768px) {
    font-size: 3vw;
  }

`;

const Footer = () => {
  return (
    <InfoContainer>
      <TextContainer>
        © 2024 Powered by Gather Impulse
      </TextContainer>
    </InfoContainer>
  );
};

export default Footer;
