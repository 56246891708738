import React from 'react';
import styled from 'styled-components';



const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 5vw 9vw;
  position: relative;
  z-index: 10;

  @media (max-width: 768px) {
    padding-top: 9vw;
  }
`;

const Titulo = styled.p`
  font-family: 'Kanit-SemiBold', sans-serif;
  color: white;
  margin: 0;
  padding: 0;
  width: 50%; /* Ajustar el ancho del contenedor */
  font-size: 2vw; /* Tamaño de fuente ajustable */
  line-height: 1.2; /* Ajustar la altura de línea según sea necesario */
  @media (max-width: 768px) {

    font-size: 5vw;
   
    width: 90%; /* Ajustar el ancho del contenedor */
  }
`;



const SubTitulo = styled.p`
  font-family: 'Kanit-ExtraLight', sans-serif;
  color: white;
  margin-top: 40px;
  padding: 0;
  width: 37%; /* Ajustar el ancho del contenedor */
  font-size: 1.3vw; /* Tamaño de fuente ajustable */
  line-height: 1.2; /* Ajustar la altura de línea según sea necesario */
  @media (max-width: 768px) {

    font-size: 3.7vw; 
    margin-top: 20px;
    width: 79%; /* Ajustar el ancho del contenedor */
  }
`;


const TituloWebs = () => {
  return (
    <InfoContainer>


      <Titulo>
      Hacemos que tu marca genere impacto y 
resultados con un diseño de producto 
totalmente personalizado.
      </Titulo>

      <SubTitulo>
      En Gather Impulse, trabajamos contigo para crear 
soluciones digitales que no solo se vean bien, sino 
que también funcionen de manera efectiva, 
ayudándote a alcanzar tus objetivos y 
destacar en el mercado
      </SubTitulo>


   

    </InfoContainer>
  );
};

export default TituloWebs;
