import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';



const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-left:  9vw;
  padding-right:  9vw;
  padding-bottom:  4vw;
  padding-top: 9vw;
  position: relative;
  z-index: 10;
  min-height: 100vh;

  @media (max-width: 768px) {
    padding-top: 29vw;
  }
`;




const InfoColumnPie = styled.div`
  display: flex;
   align-items: center; /* Cambiado a 'center' para centrar verticalmente */

  width: 100%;
  margin-top: auto; 

  @media (max-width: 768px) {
    margin-bottom: 50px; 
    width: 100vw;
    
  
   
  
  }

`;



const Titulo = styled.p`
  font-family: 'Kanit-SemiBold', sans-serif;
  color: white;
  margin: 0;
  padding: 0;
  width: 100%; /* Ajustar el ancho del contenedor */
  font-size: 3vw; /* Tamaño de fuente ajustable */
  line-height: 1.2; /* Ajustar la altura de línea según sea necesario */
  @media (max-width: 768px) {
    font-size: 8vw;
    line-height: 1.2;
  }

`;



const Subtitulo = styled.p`
  font-family: 'Kanit-ExtraLight', sans-serif;
  color: white;
  margin: 0;
  padding: 0;
  width: 100%; /* Ajustar el ancho del contenedor */
  font-size: 2.6vw; /* Tamaño de fuente ajustable */
  line-height: 1.2; /* Ajustar la altura de línea según sea necesario */
  @media (max-width: 768px) {
    font-size: 7vw;
    line-height: 1.2;
  }

`;



const Minititulo = styled.p`
  font-family: 'Kanit-Extralight', sans-serif;
  color: white;
  margin-top: 16px;
  padding: 0;
  width: 100%; /* Ajustar el ancho del contenedor */
  font-size: 1.3vw; /* Tamaño de fuente ajustable */
  line-height: 1.2; /* Ajustar la altura de línea según sea necesario */
  @media (max-width: 768px) {
    font-size: 3.7vw;
    line-height: 1.2;
  }

`;





const Bold = styled.p`
  font-family: 'Kanit-ExtraBold', sans-serif;
  color: white;

  margin: 0;
  padding: 0;
  width: 100%; /* Ajustar el ancho del contenedor */
  font-size: 1.4vw; /* Tamaño de fuente ajustable */
  line-height: 1.2; /* Ajustar la altura de línea según sea necesario */
  @media (max-width: 768px) {
    font-size: 5vw;
    line-height: 1.4;
  }

`;


const Integracion = styled.p`
 font-family: 'Kanit-Extralight', sans-serif;
  color: white;
  margin: 0;
  width: 50%; /* Ajustar el ancho del contenedor */
  font-size: 1vw; /* Tamaño de fuente ajustable */
  line-height: 1.2; /* Ajustar la altura de línea según sea necesario */
  @media (max-width: 768px) {
    font-size: 3.3vw;
    line-height: 1.2;
    width: 100%; /* Ajustar el ancho del contenedor */
    
  }
`;

const Linea = styled.div`
 position: absolute;
  width: 29%;
  height: 1px;
  right: 56vw;
  background-color: white;
  margin: 1vw 0; 
  @media (max-width: 768px) {
    right: 45vw;
    width: 27%;
  }
`;


const NuevoTexto = styled.p`
  font-family: 'Kanit-regular', sans-serif;
  color: white;
  right: 0%;
  width: 50%;
  font-size: 1vw;
  line-height: 1.2;
  text-align: right;

  @media (max-width: 768px) {
    display: none;
  }
`;

const NuevoTextoMovil = styled.p`
  font-family: 'Kanit-regular', sans-serif;
  color: #ffffffbc;
  left: 33vw;
  font-size: 3vw;
    line-height: 1.2;
  text-align: right;
  width: 60%;
  display: none;
 

  @media (max-width: 768px) {
    margin-top: -25%; 
    display: block;
    position: absolute;
  }
`;


const ButtonPresupuesto = styled(Link)`
  z-index: 2000 !important;
  position: absolute;
  font-family: 'Kanit-ExtraLight', sans-serif;
  top: 10vw;
  right: 9vw;
  
  padding: 0.2vw 2vw;
  background: transparent;
  border: 1px solid white;
  border-radius: 50px;
  color: white;
  font-size: 1.1vw;
  cursor: pointer;
  text-decoration: none; // Para asegurarse de que no hay subrayado
  transition: background-color 0.3s ease; // transición suave

  &:hover {
    background-color: #f6e82351; 
  }

  @media (max-width: 768px) {
    z-index: 2000;
    /* top: 146vw; */
    top: 90vw;
    right: 76%;
    transform: translateX(50%);
    font-size: 4vw;
  }
`;






const TituloPrincipal = () => {
  return (
    <InfoContainer>

<ButtonPresupuesto to="/contacto">PRESUPUESTO</ButtonPresupuesto>


      <Titulo>
        IMPULSANDO LA <br />
        INNOVACIÓN DIGITAL
      </Titulo>
      <Subtitulo>
        Soluciones Web y <br />
        Software a Medida
      </Subtitulo>
      <Minititulo>
        Soluciones a medida que impulsan <br />
        su transformación y crecimiento <br />
        en el mercado digital.
      </Minititulo>

   

      <InfoColumnPie>
        <Bold>UX/UI</Bold>
        <Linea />
        
        <Integracion>
          Integración de sistemas <br />
          personalizados
        </Integracion>

        <NuevoTextoMovil>
        DISEÑO WEB ESPECIALIZADO PARA TU <br />
        NEGOCIO, CON UN MANTENIMIENTO <br />
        ADAPTADO A TUS PREFERENCIAS
      </NuevoTextoMovil>

        <NuevoTexto>
          DISEÑO WEB ESPECIALIZADO PARA TU <br />
          NEGOCIO, CON UN MANTENIMIENTO <br />
          ADAPTADO A TUS PREFERENCIAS
        </NuevoTexto>
        
      </InfoColumnPie>
    </InfoContainer>
  );
};

export default TituloPrincipal;
