import React from 'react';
import styled from 'styled-components';



const Container = styled.div`
 display: flex;
  justify-content: center;  /* Centra horizontalmente */
  align-items: center;      /* Centra verticalmente */
  text-align: center;   
  
`;



const Bold = styled.strong`
    font-family: 'Kanit-SemiBold', sans-serif;
    font-size: 2.1vw;
    line-height: 1;

    @media (max-width: 768px) {
    font-size: 5vw;
   
  }
`;


const ParrafoAmarillo = styled.p`
  font-family: 'Kanit-Light', sans-serif;
  color: white;
  margin: 3vw 27vw 3vw 27vw;
  padding: 0;
  font-size: 2vw;
  line-height: 1.0;

  color: #D9C01E; 

  @media (max-width: 768px) {
    margin: 3vw 4vw 3vw 4vw;
    font-size: 5vw;
    line-height: 1.1;
  }
`;






const TituloPrincipal = () => {
  return (


    <Container>
   

   
      <ParrafoAmarillo>
     <Bold>Aún no tienes una web</Bold> o si la que tienes no te 
      está trayendo clientes
        
      </ParrafoAmarillo>

     
   
    
    </Container>
  );
};

export default TituloPrincipal;
