import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 8vw 2vw;
  position: relative;
  z-index: 10;

  @media (max-width: 768px) {
    padding: 15vw 2vw;
  }
`;

const Titulo = styled.p`
  font-family: 'Kanit-SemiBold', sans-serif;
  color: white;
  margin: 0;
  padding: 0;
  width: 90%;
  font-size: 3vw;
  line-height: 1.2;
  text-align: center;

  @media (max-width: 768px) {
    font-size: 7vw;
  }
`;

const SubTituloBold = styled.p`
  font-family: 'Kanit-ExtraLight', sans-serif;
  color: white;
  margin-top: 40px;
  padding: 0;
  width: 67%;
  font-size: 1.6vw;
  line-height: 1.2;
  text-align: center;

  @media (max-width: 768px) {
    font-size: 4vw;
    margin-top: 20px;
    width: 80%;
  }
`;

const Button = styled(Link)`
  font-family: 'Kanit-ExtraLight', sans-serif;
  margin-top: 60px;
  padding: 0.2vw 2vw;
  background: transparent;
  border: 1px solid white;
  border-radius: 50px;
  color: white;
  font-size: 1.4vw;
  cursor: pointer;
  text-decoration: none;
  transition: background-color 0.3s ease, color 0.3s ease;

  @media (max-width: 768px) {
    margin-top: 30px;
    padding: 0.5vw 6vw;
    font-size: 4.9vw;
  }

  &:hover {
    background-color: #f6e8236f;
  }
`;

const FooterTitulo = () => {
  return (
    <InfoContainer>
      <Titulo>Transformación de la experiencia del cliente</Titulo>
      <SubTituloBold>
        Ayudamos a los clientes a definir su estrategia, a elegir la plataforma óptima 
        en función de la necesidad y a acelerar la generación de valor.
      </SubTituloBold>
      <Button to="/contacto">CONTACTAR</Button>
    </InfoContainer>
  );
};

export default FooterTitulo;
