import React from 'react';
import styled from 'styled-components';

const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-left: 10vw;
  padding-right: 10vw;
  padding-bottom: 2vw;
  padding-top: 0vw;
  position: relative;
  z-index: 10;

  text-align: center; /* Alinea el texto al centro */

  @media (max-width: 768px) {
    
    padding-left: 7vw;
    padding-right: 7vw;
    padding-top: 0vw;
  }
`;


const Parrafo = styled.p`
   /* font-family: 'Kanit-SemiBold', sans-serif; */
   font-family: 'Kanit-SemiBold', sans-serif;
  color: white;
  margin: 0vw 0 0vw 0;
  padding: 0;
  width: 100%;
  font-size: 1.7vw;
  line-height: 1.2;

  @media (max-width: 768px) {
    margin: 3vw 0 0vw 0;
    font-size: 5vw;
    line-height: 1.2;
  }
`;


const ParrafoAmarillo = styled.p`
  font-family: 'Kanit-SemiBold', sans-serif;
  margin: 3vw 7vw 3vw 7vw;
  padding: 0;
  font-size: 2vw;
  line-height: 1.0;

  color: #D9C01E; 

  @media (max-width: 768px) {
    margin: 3vw 4vw 3vw 4vw;
    font-size: 5vw;
    line-height: 1.1;
  }
`;


const ParrafoBlanco = styled.p`
   /* font-family: 'Kanit-SemiBold', sans-serif; */
   font-family: 'Kanit-Light', sans-serif;
  color: white;
  margin: 1vw 0 0vw 0;
  padding: 0;
  width: 100%;
  font-size: 1.2vw;
  line-height: 1.2;

  @media (max-width: 768px) {
    margin: 3vw 0 3vw 0;
    font-size: 4vw;
    line-height: 1.2;
  }
`;



const TituloPrincipal = () => {
  return (
    <InfoContainer>

<ParrafoAmarillo>
Imagina poder automatizar la mayoría 
de procesos para adquisición de 
nuevos clientes
     </ParrafoAmarillo>


      <Parrafo>


      ¿Por qué esperar?


      </Parrafo>


      <ParrafoBlanco>


      Cada día que pasas sin una web optimizada es un día en que tu competencia te está ganando la carrera, se queda con tus clientes y el tiempo 
      pasa para todos, para ti también !


</ParrafoBlanco>

      



    </InfoContainer>
  );
};

export default TituloPrincipal;
