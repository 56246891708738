import React from 'react';
import styled from 'styled-components';


const Container = styled.div`
 display: flex;
  justify-content: center;  
  align-items: center;      
`;


const Button = styled.button`
  background-color: #42b8276e;
  color: white;
  border: none;
  border-radius: 46px;
  cursor: pointer;
  font-family: 'Kanit-SemiBold', sans-serif;
  border: 1px solid #ffffffcf;
  width: 60%;
  font-size: 1.7vw;
  margin-top: 0px;

  @media (max-width: 768px) {
    width: 70%;
    padding: 4px;
    font-size: 4vw;
    margin-bottom: 20px;
    margin-top: 0px;
    line-height: 1.0;
  }
`;



const Boton = ({ scrollToCalendly }) => {


  return (
    <Container>

  
           

<Button onClick={scrollToCalendly}> ¿Te suena familiar? Agenda una llamada y lo resolvemos </Button>

     
    </Container>
  );
};

export default Boton;
