import React from 'react';
import styled from 'styled-components';

const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-left: 2vw;
  padding-right: 2vw;
  padding-bottom: 2vw;
  padding-top: 4vw;
  position: relative;
  z-index: 10;

  text-align: center; /* Alinea el texto al centro */

  @media (max-width: 768px) {
    
    padding-left: 7vw;
    padding-right: 7vw;
    padding-top: 15vw;
  }
`;

const Titulo = styled.p`
  font-family: 'Kanit-SemiBold', sans-serif;
  color: white;
  margin: 0 0 3vw 0;
  padding: 0;
  width: 100%;
  font-size: 4vw; /* Aumenté el tamaño de la fuente */
  line-height: 1.2;
  letter-spacing: 0.35em; /* Añadí espacio entre las letras */
  z-index: 55000 !important;

  @media (max-width: 768px) {
    font-size: 7vw; /* También aumenté el tamaño para pantallas pequeñas */
    line-height: 1.;
    letter-spacing: 0.30em; /* Añadí espacio entre las letras */
  }
`;


const Subtitulo = styled.p`
   font-family: 'Kanit-SemiBold', sans-serif;
  color: white;
  margin: 0vw 0 0vw 0;
  padding: 0;
  width: 100%;
  font-size: 2vw;
  line-height: 1.2;

  @media (max-width: 768px) {
    margin: 3vw 0 0vw 0;
    font-size: 4vw;
    line-height: 1.2;
  }
`;




const ParrafoPequeño = styled.p`
  font-family: 'Kanit-Light', sans-serif;
  color: white;
  margin: 0vw 0 1vw 0;
  padding: 0;
  font-size: 1vw;
  line-height: 1.2;

  @media (max-width: 768px) {
    margin: 0vw 0 0vw 0;
    font-size: 4vw;
    line-height: 1.2;
  }
`;


const BoldPequeño = styled.strong`
    font-family: 'Kanit-SemiBold', sans-serif;
    font-size: 1.1vw;
    line-height: 1;

    @media (max-width: 768px) {
      font-size: 4.1vw;
   
  }
`;







const TituloPrincipal = () => {
  return (
    <InfoContainer>

      <Titulo>
      FRUSTRADO POR <br />
      LA TECNOLOGÍA  
      
       
      </Titulo>

      <Subtitulo>
      ¿Tu WEB es INEXISTENTE o No Convierte? 
      </Subtitulo>

      <ParrafoPequeño>
      Vale, vamos a cambiar eso en solo 30 minutos <BoldPequeño>(Consultoría GRATIS)</BoldPequeño>
      </ParrafoPequeño>




    </InfoContainer>
  );
};

export default TituloPrincipal;
