import React from 'react';
import styled from 'styled-components';



const Titulo = styled.p`
z-index: 30;
  font-family: 'Kanit', sans-serif;
  color: white;
  text-align: center;
  margin-top: 60px;
  left: 0;
  right: 0;
  padding: 0;
  font-size: 13vw; /* Tamaño de fuente ajustable */
  width: 100vw; 
  margin-bottom: -20px;

  @media (max-width: 768px) {
    margin-bottom: 40px;
    margin-top: 110px;
  }
`;

const InfoContainer = styled.div`
  padding: 0vw 10vw;

  top: 205px;
z-index: 30;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%; 


  @media (max-width: 768px) {
    top: 100px;
    width: 100%; /* Ancho máximo en dispositivos móviles */
    margin: 0; /* Eliminar márgenes para asegurar centrado */
  }
`;

const InfoColumn = styled.div`
z-index: 30;
  display: flex;
  justify-content: space-between;
  width: 100%; /* Ajustar según sea necesario */

  @media (max-width: 768px) {


    flex-direction: column;
    align-items: center;
    width: 100%; /* Ancho completo en dispositivos móviles */
  }


  
`;

const Info3 = styled.p`
  font-family: 'Kanit-ExtraLight', sans-serif;
  color: white;
  /* margin: 10px; */
  padding: 0;
  width: 45%; /* Ajustar según sea necesario */
  font-size: 1.1vw; 
  line-height: 1.2; 

  @media (max-width: 768px) {
    font-size: 4.1vw;
    line-height: 1.0;
    width: 100%; 
  }
`;

const Info4 = styled.p`
z-index: 20;

  font-family: 'Kanit', sans-serif;
  color: white;
  margin: 0;
  padding: 0;
  width: 45%; /* Ajustar según sea necesario */
  font-size: 1vw; /* Tamaño de fuente ajustable */
  line-height: 1.2; /* Ajustar la altura de línea según sea necesario */
  text-align: right;


  @media (max-width: 768px) {
    font-size: 4.0vw;
    line-height: 1.4;
    width: 100%; /* Ancho completo en dispositivos móviles */
    text-align: center; /* Centrar en dispositivos móviles */
  }



`;

const Linea = styled.div`
  width: 60%;
  height: 1px;
  background-color: white;


  @media (max-width: 768px) {
    width: 100%;
    margin: 5vw 0; 
    margin-bottom: 30px; 
  }
`;

const Home = () => {
  return (

<p>
      <Titulo>CONTACTO</Titulo>

      <InfoContainer>
        <InfoColumn>
          <Info3>
            Estamos aquí para acompañarte en tu viaje digital. - Cuéntanos qué necesitas y nos pondremos en contacto contigo antes de que te des cuenta.
          </Info3>
          <Linea />
          <Info4>
          info@gatherimpulse.com <br></br>C/ Mar Menor s/n 41015 Sevilla <br></br> + 34 655 416 503
          </Info4>
        </InfoColumn>
        
      </InfoContainer>
</p>
  );
};

export default Home;
