import React from 'react';
import styled from 'styled-components';

import Fluid3D1 from './Fluid 3D 1.png';
import Fluid3D2 from './Fluid 3D 2.png';
import Fluid3D3 from './Fluid 3D 3.png';
import Fluid3D4 from './Fluid 3D 4.png';
import Fluid3D5 from './Fluid 3D 5.png';
import Fluid3D6 from './Fluid 3D 6.png';

import Menu from "../../components/Menu/menu";
import Header from "../../components/Header/header";

import PopUp from "../../components/PopUp/PopUp";

import TituloPrincipal from "../../components/TituloPrincipal/tituloprincipal";
import Works from "../../components/Works/works";
import LetraWorks from "../../components/LetraWorks/letraworks";
import Cards from "../../components/Cards/cards";
import TituloWebs from "../../components/TituloWebs/titulowebs";
import Webs from "../../components/Webs/webs";
import TextoInfinito from "../../components/TextoInfinito/textoinfinito";
import LetraAboutUs from "../../components/LetraAboutUs/letraaboutus";
import TituloAboutUs from "../../components/TituloAboutUs/tituloaboutus";
import TituloMetodologias from "../../components/TituloMetodologias/titulometodologias";
import ImagenMetodologias from "../../components/ImagenBranding/branding";
import Metodos from "../../components/Metodos/Metodos";
import MetodosMovil from "../../components/MetodosMovil/metodosmovil";
import TituloDesign from "../../components/TituloDesign/TituloDesign";
import ImagenDesign from "../../components/ImagenDesign/ImagenDesign";
import Linea from "../../components/Linea/Linea";
import CardsFooter from "../../components/CardsFooter/CardsFooter";
import FooterTitulo from "../../components/TituloFooter/TituloFooter";
import FooterHeader from "../../components/Footer/FooterHeader/FooterHeader";
import FooterBoody from "../../components/Footer/FooterBoody/FooterBoody";
import FooterButton from "../../components/Footer/FooterButton/FooterButton";




const BackgroundImagesContainer = styled.div`

  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: -1;

`;

const Image = styled.img`
  width: 100%;
  height: auto; /* Maintain aspect ratio */
  position: absolute;
  opacity: 0.7;
  z-index: 0; /* Asegura que las imágenes estén detrás del contenido */
`;

const Image1 = styled(Image)`

top:-1%;
  @media (max-width: 768px) {
    top:6.9%;
  }
`;

const Image2 = styled(Image)`

top:14%;

  @media (max-width: 768px) {
    top:23%;
  }
`;


const Image3 = styled(Image)`

top:29%;


  @media (max-width: 768px) {
    top:36.5%;
  }
`;

const Image4 = styled(Image)`
top:45%;
  @media (max-width: 768px) {
    top:45.5%;
  }
`;

const Image5 = styled(Image)`
top:63%;
  @media (max-width: 768px) {
    top:70.5%;
  }
`;

const Image6 = styled(Image)`

top:86.3%;
/* top: 7463px;  */
  @media (max-width: 768px) {
    top:95%;
  }
`;




const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  overflow-x: hidden;
  position: relative;
  width: 100%;
  background-color: #0a0a0a;

`;


const BlackOverlay = styled.div`
 pointer-events: none;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: black;
  z-index: 1000;
  animation: fadeOut 0.7s ease-in-out forwards;

  @keyframes fadeOut {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
      display: none;
    }
  }
`;




const Home = () => {
  return (
    <div style={{ position: 'relative' }}>
      <BlackOverlay />
      <ContentContainer>
        <Menu />
        <Header />




        <PopUp />
        <TituloPrincipal />

        <Works />
        <div id="work">  
        <LetraWorks />
        </div>
        <Cards />
        <TituloWebs />
        <Webs />
        <TextoInfinito />
        <div id="about-us">
          <LetraAboutUs />
          <TituloAboutUs />
        </div>
        <TituloMetodologias />
        <ImagenMetodologias />
        <Metodos />
        <MetodosMovil />
        <Linea />
        <TituloDesign />
        <ImagenDesign />
        <CardsFooter />
        <FooterTitulo />
        <FooterHeader />
        <FooterBoody />
        <FooterButton />

      </ContentContainer>

      <BackgroundImagesContainer>

        <Image1 src={Fluid3D1} alt="Background Image 1" />
        <Image2 src={Fluid3D2} alt="Background Image 2" />
        <Image3 src={Fluid3D3} alt="Background Image 3" />
        <Image4 src={Fluid3D4} alt="Background Image 4" />
        <Image5 src={Fluid3D5} alt="Background Image 5" />
        <Image6 src={Fluid3D6} alt="Background Image 6" />



      </BackgroundImagesContainer>


    </div>

  );
};

export default Home;
