import React from 'react';
import styled from 'styled-components';


const Container = styled.div`
 display: flex;
  justify-content: center;  /* Centra horizontalmente */
  align-items: center;      /* Centra verticalmente */
`;


const Button = styled.button`
  background-color: #42b8276e;
  color: white;
  border: none;
  border-radius: 46px;
  cursor: pointer;
  font-family: 'Kanit-SemiBold', sans-serif;
  border: 1px solid #ffffffcf;
  width: 30%;
  font-size: 1.7vw;
  margin-top: 20px;

  @media (max-width: 768px) {
    width: 70%;
    padding: 4px;
    font-size: 4vw;
    margin-bottom: 20px;
    margin-top: 20px;
  
  }
`;



const Boton = ({ scrollToCalendly }) => {


  return (
    <Container>

  
           

<Button onClick={scrollToCalendly}>¡Agenda tu llamada ahora!</Button>

     
    </Container>
  );
};

export default Boton;
