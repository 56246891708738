import React from 'react';
import styled from 'styled-components';
import Logo from './Logo_blanco.png'; // Asegúrate de ajustar la ruta según la estructura de tu proyecto

const InfoContainer = styled.div`
  padding: 0vw 9vw;
  display: flex;
  justify-content: space-between; 
  align-items: center; 
  color: white; 
z-index: 100;
  @media (max-width: 1268px) {
   
    flex-direction: column;
    text-align: center;
  }
`;

const LogoImage = styled.img`
z-index: 3;
width: 31% ;
  margin-right: 60px;
  @media (max-width: 768px) {
    margin-right: 0px;
   width: 100% ;
  }
`;
const TextContainer = styled.div`
z-index: 3;
  width: 95%;
  font-size: 1.3vw; /* Tamaño de fuente ajustable */
  font-family: 'Kanit-ExtraLight', sans-serif;
  text-align: left; /* Alinear texto a la derecha */

  @media (max-width: 768px) {
    text-align: center; /* Centrar texto en dispositivos pequeños */
    font-size: 3vw; /* Tamaño de fuente ajustable */
  }
`;


const Linea = styled.div`
  z-index: 10;
  top: 10px;
width: 80%;
  height: 1px;
  background-color: white;
  margin: 0 auto; // Esto centra la línea horizontalmente
  position: relative;
  margin-bottom: 10px;

  @media (max-width: 768px) {
    top: 10px;
    width: 80%;
  }
`;


const Footer = () => {
  return (
    <p>
    <Linea />
    <InfoContainer>
      <LogoImage src={Logo} alt="Logo" />
      <TextContainer>
        <div>
          DISEÑO DE PRODUCTO ESPECIALIZADO PARA TU NEGOCIO, CON UN MANTENIMIENTO ADAPTADO A TUS NECESIDADES
        </div>
      </TextContainer>
    </InfoContainer>


    </p>
  );
};

export default Footer;
