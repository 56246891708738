import React, { useEffect } from 'react';
import styled from 'styled-components';

const InfoContainer = styled.div`
  /* Estilo predeterminado para pantallas grandes */
  .calendly-inline-widget {
    min-width: 600px;
    height: 900px;
  }

  @media (max-width: 768px) {
    /* Estilos para pantallas móviles */
    .calendly-inline-widget {
      min-width: 320px;
      height: 700px;
    }
  }

  @media (min-width: 768px) and (max-width: 1200px) {
    /* Estilos para pantallas medianas */
    .calendly-inline-widget {
      min-width: 500px;
      height: 800px;
    }
  }
`;

const TituloPrincipal = () => {
  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://assets.calendly.com/assets/external/widget.js';
    script.async = true;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <InfoContainer>
      <div
        className="calendly-inline-widget"
        data-url="https://calendly.com/alexgatherimpulse/30min?background_color=000000&text_color=fdfdfd&primary_color=fff000"
      ></div>
    </InfoContainer>
  );
};

export default TituloPrincipal;
