import React from 'react';
import styled from 'styled-components';

const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  padding: 8vw 9vw;
  position: relative;
  z-index: 10;

  @media (max-width: 768px) {
    padding-top: 9vw;
  }
`;

const Titulo = styled.p`
  font-family: 'Kanit-SemiBold', sans-serif;
  color: white;
  margin: 0;
  padding: 0;
  width: 70%; /* Ajustar el ancho del contenedor */
  font-size: 3vw; /* Tamaño de fuente ajustable */
  line-height: 1.2; /* Ajustar la altura de línea según sea necesario */
  text-align: right; /* Alinear el texto a la derecha */
  @media (max-width: 768px) {
    font-size: 5vw;
    width: 80%; /* Ajustar el ancho del contenedor */
  }
`;

const SubTitulo = styled.p`
  font-family: 'Kanit-ExtraLight', sans-serif;
  color: white;
  margin-top: 0px;
  padding: 0;
  width: 77%; /* Ajustar el ancho del contenedor */
  font-size: 2.8vw; /* Tamaño de fuente ajustable */
  line-height: 1; /* Ajustar la altura de línea según sea necesario */
  text-align: right; /* Alinear el texto a la derecha */
  @media (max-width: 768px) {
    font-size: 4.3vw; 
    margin-top: 0px;
    width: 79%; /* Ajustar el ancho del contenedor */
  }
`;

const Texto = styled.p`
  font-family: 'Kanit-ExtraLight', sans-serif;
  color: white;
  margin-top: 40px;
  padding: 0;
  width: 66%; /* Ajustar el ancho del contenedor */
  font-size: 1.9vw; /* Tamaño de fuente ajustable */
  line-height: 1.2; /* Ajustar la altura de línea según sea necesario */
  text-align: right; /* Alinear el texto a la derecha */
  @media (max-width: 768px) {
    font-size: 3.7vw; 
    margin-top: 1px;
    width: 84%; /* Ajustar el ancho del contenedor */
  }
`;

const TituloMetodologias = () => {
  return (
    <InfoContainer>
      <Titulo>
        CREEMOS EN METODOLOGÍAS COMO EL
      </Titulo>
      <SubTitulo>
        LEAN & DESIGN THINKING
      </SubTitulo>
      <Texto>
        Ponemos especial atención en colaborar y obterner
        feedback constante, el objetivo es no dar nada por
        sentado y conseguir desarrollar proyectos
        con el menor riesgo, la máxima agilidad y el
        mayor impacto.
      </Texto>
    </InfoContainer>
  );
};

export default TituloMetodologias;
