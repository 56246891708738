import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom'; // Importar Link
import VideoFluid from "../../components/VideoFluid/VideoFluid";



const BackgroundImages = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  background-color: #0A0A0A;
`;

const Logo = styled.img`
  height: 4vw;
  position: absolute;
  top: 3vw;
  left: 0;
  right: 0;
  margin: auto;
  @media (max-width: 768px) {
    top: 15vw;
    height: 13vw;
  }
`;

const Titulo = styled.p`
  font-family: 'Kanit', sans-serif;
  color: white;
  text-align: center;
  position: absolute;
  top: 5vw;
  left: 0;
  right: 0;
  margin: 0;
  padding: 0;
  font-size: 15vw; /* Tamaño de fuente ajustable */
  width: 100vw; /* Utilizamos 100% del ancho de la pantalla */


  @media (max-width: 768px) {
    top: 30vw;
   
  }

`;

const InfoContainer = styled.div`
 position: fixed;
 bottom: 0; /* Ajustar según sea necesario */
  display: flex;
  flex-direction: column; /* Cambiamos la dirección a columna */
  left: 42%;
  transform: translateX(-50%);
 
  margin-top: 1vw; /* Añadir margen superior para separación */



  @media (max-width: 768px) {
    bottom: 13vw;
    left: 50%;
    transform: translateX(-50%);
    width: 90vw; /* Asegurar que el contenedor no exceda el ancho de la pantalla */
    align-items: center; /* Centrar elementos dentro del contenedor */
    margin: 0; 
    margin-bottom: 10px; 
  
  }
`;


const InfoColumn = styled.div`
  display: flex;
   align-items: center; /* Cambiado a 'center' para centrar verticalmente */
  margin-bottom: 5vw; /* Añadir margen inferior para separación entre filas */

  width: 72vw;

  @media (max-width: 768px) {
    width: 88vw;
    gap: 10px;

  }

`;

const Info1 = styled.p`
  font-family: 'Kanit', sans-serif;
  color: white;

  margin: 0;
  padding: 0;
  width: 50%; /* Ajustar el ancho del contenedor */
  font-size: 1vw; /* Tamaño de fuente ajustable */
  line-height: 1.2; /* Ajustar la altura de línea según sea necesario */
  @media (max-width: 768px) {
    font-size: 2.9vw;
    line-height: 1.4;
  }
  .no-br-mobile {
    @media (max-width: 768px) {
      display: none;
    }
  }
`;

const Info2 = styled.p`
 font-family: 'Kanit', sans-serif;
  color: white;
  margin: 0;
  padding: 0; /* Espacio a la izquierda para separación */
  width: 50%;
  font-size: 1vw; /* Tamaño de fuente ajustable */
  line-height: 1.2; /* Ajustar la altura de línea según sea necesario */
  @media (max-width: 768px) {
    font-size: 3vw;
    line-height: 1.4;
  }
  .no-br-mobile {
    @media (max-width: 768px) {
      display: none;
    }
  }
`;


const Info3 = styled.p`
  font-family: 'Kanit-ExtraBold', sans-serif;
  color: white;

  margin: 0;
  padding: 0;
  width: 50%; /* Ajustar el ancho del contenedor */
  font-size: 1.4vw; /* Tamaño de fuente ajustable */
  line-height: 1.2; /* Ajustar la altura de línea según sea necesario */
  @media (max-width: 768px) {
    font-size: 5vw;
    line-height: 1.4;
  }
  .no-br-mobile {
    @media (max-width: 768px) {
      display: none;
    }
  }
`;

const Info4 = styled.p`
  font-family: 'Kanit', sans-serif;
  color: white;

  margin: 0;
  width: 50%; /* Ajustar el ancho del contenedor */
  font-size: 1vw; /* Tamaño de fuente ajustable */
  line-height: 1.2; /* Ajustar la altura de línea según sea necesario */
  @media (max-width: 768px) {
    font-size: 2.9vw;
    line-height: 1.4;
  }
  .no-br-mobile {
    @media (max-width: 768px) {
      display: none;
    }
  }
`;
const Linea = styled.div`
 position: absolute;
  width: 36%;
  height: 1px;
  right: 39vw;
  background-color: white;
  margin: 1vw 0; /* Espacio arriba y abajo */
  @media (max-width: 768px) {
    right: 47vw;
    width: 27%;
  }
`;


const ButtonHome = styled(Link)`
  z-index: 2000 !important;
  position: absolute;
  font-family: 'Kanit-ExtraLight', sans-serif;
  top: 9vw;
  right: 5vw;
  padding: 0.2vw 2vw;
  background: transparent;
  border: 1px solid white;
  border-radius: 50px;
  color: white;
  font-size: 1.1vw;
  cursor: pointer;
  text-decoration: none; // Para asegurarse de que no hay subrayado
  transition: background-color 0.3s ease; // transición suave

  &:hover {
    background-color: #f6e82351; 
  }

  @media (max-width: 768px) {
    z-index: 2000;
    top: 140px;
    right: 91%;
    transform: translateX(50%);
    font-size: 4vw;
    margin-bottom: 50px;
  }
`;

const ButtonPresupuesto = styled(Link)`
  z-index: 2000 !important;
  position: absolute;
  font-family: 'Kanit-ExtraLight', sans-serif;
  top: 9vw;
  right: -15vw;
  padding: 0.2vw 2vw;
  background: transparent;
  border: 1px solid white;
  border-radius: 50px;
  color: white;
  font-size: 1.1vw;
  cursor: pointer;
  text-decoration: none; // Para asegurarse de que no hay subrayado
  transition: background-color 0.3s ease; // transición suave

  &:hover {
    background-color: #f6e82351; 
  }

  @media (max-width: 768px) {
    z-index: 2000;
    top: 140px;
    right: 33%;
    transform: translateX(50%);
    font-size: 4vw;
    margin-bottom: 50px;
  }
`;

const BlackOverlay = styled.div`
 pointer-events: none;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: black;
  z-index: 1000;
  animation: fadeOut 0.7s ease-in-out forwards;

  @keyframes fadeOut {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
      display: none;
    }
  }
`;



const Home = () => {
  const handleTouch = (e) => {
    e.stopPropagation();
  };

  return (
<>
         <BlackOverlay />
      <BackgroundImages>
        <Logo src="/Logo letra.png" alt="Logo" />

        <Titulo>SOFTWARE</Titulo>

        <InfoContainer>
          <InfoColumn>
            <Info1>
              DISEÑO WEB ESPECIALIZADO PARA TU <br className="no-br-mobile" />
              NEGOCIO, CON UN MANTENIMIENTO <br className="no-br-mobile" />
              ADAPTADO A TUS PREFERENCIAS
            </Info1>
            <Info2>
              Soluciones a medida que impulsan <br className="no-br-mobile" />
              su transformación y crecimiento <br className="no-br-mobile" />
              en el mercado digital
            </Info2>
          </InfoColumn>

          <InfoColumn>
            <Info3>
              UX/UI
            </Info3>
            <Linea />
            <Info4>
              Integración de sistemas <br className="no-br-mobile" />
              personalizados
            </Info4>
          </InfoColumn>

          <ButtonHome to="/home" onTouchStart={handleTouch}>HOME</ButtonHome>
          <ButtonPresupuesto to="/contacto" onTouchStart={handleTouch}>PRESUPUESTO</ButtonPresupuesto>
        </InfoContainer>



        <VideoFluid/>
      </BackgroundImages>

   </>
    

  );
};

export default Home;