import React from 'react';
import styled from 'styled-components';



const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 3vw 9vw;
  position: relative;
  z-index: 10;

  @media (max-width: 768px) {
    padding-top: 9vw;
  }
`;

const Titulo = styled.p`
  font-family: 'Kanit-ExtraLight', sans-serif;
  color: white;
  margin: 0;
  padding: 0;
  width: 70%; /* Ajustar el ancho del contenedor */
  font-size: 2vw; /* Tamaño de fuente ajustable */
  line-height: 1.2; /* Ajustar la altura de línea según sea necesario */
  @media (max-width: 768px) {

    font-size: 5vw;
   
    width: 90%; /* Ajustar el ancho del contenedor */
  }
`;


const Linea = styled.div`

  width: 63%;
  height: 1px;
  right: 41vw;
  background-color: white;
  margin: 1vw 0; 
  @media (max-width: 768px) {
    margin: 3vw 0; 
    width: 100%;
  }
`;



const TituloWebs = () => {
  return (
    <InfoContainer>
      <Titulo>
        Casos de éxito y clientes satisfechos con el proyecto que
        cambió su forma de ver el mundo digital.
      </Titulo>


      <Linea />




    </InfoContainer>
  );
};

export default TituloWebs;
